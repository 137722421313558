import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import miniprice from '../views/miniprice.vue'
import afterSales from '../views/afterSales.vue'
import frequentlyQus from '../views/frequentlyQus.vue'
import about from '../views/AboutView.vue'
import detection from '../components/detection.vue'
import store_fir from '../views/store_fir.vue'
import backquet from '../views/backquet.vue'
import paintingquet from '../views/paintingquet.vue'
import Detail from '../components/Detail.vue'
import custCase from '../views/custCase.vue'
import freeRefund from '../views/freeRefund.vue'
import fineStore from '../views/fineStore.vue'
import store from '../views/store'
import store_sec from '../views/store_sec.vue'
import test from '../components/test.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/price',
    name: 'price',
    component: miniprice
  },

  {
    path: '/afterSales',
    name: 'afterSales',
    component: afterSales
  },
  {
    path: '/frequentlyQus',
    name: 'frequentlyQus',
    component: frequentlyQus
  },
  {
    path: '/fineStore',
    name: 'fineStore',
    component: fineStore
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/detection',
    name: 'detection',
    component: detection
  }, 
  {
    path: '/store',
    name: 'store',
    component: store,
  },
  {
    path: '/store_fir',
    name: 'store_fir',
    component: store_fir
  }, 
  {
    path: '/store_sec',
    name: 'store_sec',
    component: store_sec
  }, 
  // {
  //   path: '/paintingquet',
  //   name: 'paintingquet',
  //   component: paintingquet,

  //   children: [
  //     {
  //       path: 'Detail',
  //       name: 'Detail',
  //       component: Detail,
  //       props: true, // 将路由参数传递给子组件作为 props
  //       meta: {
  //         keepAlive: false 
  //       }
  //     }
  //   ]
  // },
  {
    path: '/backquet',
    name: 'backquet',
    component: backquet
  },{
    path: '/custCase',
    name: 'custCase',
    component: custCase
  },
  {
    path: '/freeRefund',
    name: 'freeRefund',
    component: freeRefund
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
