<template>
    <div class="row-high">
        <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">
          <el-icon><MoonNight /></el-icon>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="显卡检测方法一" name="first">
                    <div>
                        <br>
                        1.同时按下Ctrl + Shift + Esc键。打开任务管理器，然后点击“性能”
                        <br>
                        <img src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E6%98%BE%E5%8D%A1%E6%A3%80%E6%B5%8B/1.png" alt="Image">
                        <br>
                        2.在“性能”页面中找到“GPU”选项，点击“GPU”，右上角便是您的显卡种类
                        <br>
                        <img src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E6%98%BE%E5%8D%A1%E6%A3%80%E6%B5%8B/2.png" alt="Image">
                        <br>
                        3.记下您的显卡种类，与显卡对照表中找到您的显卡
                        <br>
                        <a href="https://daemao.top/finProduquet" style="color:red">若您的显卡种类排名等于或高于红框的位置</a>，则满足我们的显卡要求
                        <br>
                        <img src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E6%98%BE%E5%8D%A1%E6%A3%80%E6%B5%8B/4.png" alt="Image">
                    </div>
                </el-tab-pane>
                <el-tab-pane label="显卡检测方法二" name="second">
                    <div>
                        <el-link type="primary" href="http://gpu.jy6d.com/">1.电脑开启“独显直连”，然后直接点击该链接可以直接看到您的的显卡种类</el-link>
                        <br>
                        <img src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E6%98%BE%E5%8D%A1%E6%A3%80%E6%B5%8B/3.png" alt="Image">
                        <br>
                        2.开启独显直连的方法一般可以百度您电脑的品牌（例如百度：惠普如何开启独显直连？）
                        <br>
                        <br>
                        3.记下您的显卡种类，与显卡对照表中找到您的显卡
                        <br>
                        <a href="https://daemao.top/finProduquet" style="color:red">若您的显卡种类排名等于或高于红框的位置</a>，则满足我们的显卡要求
                        <br>
                        <img src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E6%98%BE%E5%8D%A1%E6%A3%80%E6%B5%8B/4.png" alt="Image">
                    </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="显卡对照表" name="third">
                    <div>
                        <br>
                        若您的显卡种类排名等于或高于红框的位置，则满足我们的显卡要求
                        <br>
                        <img src="../img/detection/4.png" alt="Image">
                    </div>
                </el-tab-pane> -->
            </el-tabs>
        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        </el-row>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: 'first'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>

<style>
.row-high{
margin-top: 40px; /* 设置上边距为 50px，可以根据需要调整数值 */
}
</style>