<template>
  <el-row :gutter="10">
  <el-col :xs="1" :sm="1" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple"></div></el-col>
  <el-col :xs="22" :sm="22" :md="14" :lg="14" :xl="14">
    <div class="row-high">
      <PriceTable></PriceTable>
    <el-divider><i class="bi bi-brightness-alt-high"></i></el-divider>

    <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"><div></div><br></el-col>
    <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22"><div>


    <div>
    
    <h1 class="title-container">制作工期:</h1>

    <p class="tp">AI动捕工期为三个工作日（含出图和修图），加急或自带图、成品库的情况一般可当天出。</p>
    <p class="tp">手工live2d的工期约为四至十五个工作日，进度会随时跟您汇报，模型的部件数量越多精度越高。高精度模型可动的部件数量较多，层次感和表现力也更强。但同时也需要更多的工时开销来完成模型的制作。是影响模型制作费用的重要参数。</p>
    <p class="tp">补充说明：手工live2d由于有半身、全身，普通拆分、精细拆分，以及是否需要动画和角色不同导致的差别，其价格可能会有上下浮动，具体定价请提前咨询客服。</p>

    <br>
    <br>

    <el-divider><i class="bi bi-brightness-alt-high"></i></el-divider>
    <h2 class="title-container">流程说明:</h2>
    <br>
    <br>
    <h3 class="title-container2"><a style="color: red; font-size: larger" href="https://jxjcqzik9sc.feishu.cn/docx/Rc6udMGSKobl60xpHuhcj1J1nae">大鹅猫工作室ive2d说明文档（点击跳转）</a></h3> 
    <br>

    <h3 class="title-container2"><a style="color: rgb(0, 0, 0); font-size: larger">图片定制流程：</a></h3>
        <br>
        <div>
          <p class="tp">
            <a style="color: blue; font-size: larger">高精度人设插画服务流程：</a>
          </p>
          <p class="tp">
            1.首先您选择一种插画服务，在画风库选择喜欢的画风。由我们生成对应数量的草图并全部发给您。
          </p>
          <p class="tp">
            2.您从草图中挑选您喜欢的图片并交由我们修复细节和放大，若开始修图则不再支持全额退款，其余草图作为赠品您也可以自由保存。
          </p>

          <p class="tp">
            3.修复完毕后，我们发给您确认图，若确认无问题则对结果图片分别进行直接放大和重绘放大，返给您精细程度各不相同的高清大图。
          </p>
        </div>

        <p class="note tp">
          补充说明：所有图片和模型工作室不会长期保存，请您及时下载，若因未及时下载造成的损失，工作室不承担任何责任。
        </p>
        <br>
        <br>
        <br>

    <h3 class="title-container2"><a style="color: blue; font-size: larger">live2d 动捕服务流程:</a></h3>
    <br>
      <p class="tp">大鹅猫工作室提供两套live2d方案，一套是<a style="color: red;font-size: 15px;">ai动捕的方案</a>，一套是<a style="color: red;font-size: 15px;">手工live2d</a>的方案。</p>
      <p class="tp">前者价格低、制作快，只支持半身动捕，暂不支持物理效果，非常适合试播、低成本开播、初步检查live2d效果等</p>
      <p class="tp">后者价格稍高，制作周期较长，但是效果灵动，支持全身、头发、服饰的物理效果，适合正式的直播场合。</p>
      <br>
      <p class="tp"><a style="color: red;font-size: 15px;">live2d 服务流程：</a></p>
      <p class="tp"><a style="color: blue;font-size: 15px;">首先需要有一张您的角色图</a>，可以由我们定做，也可以您自己带图，或者挑选成品库里的图。</p>
      <p class="tp">一条龙、定制的服务都包含定制角色和修图的服务，具体价格可以参考价格表，草图阶段不满意可以全额退款。</p>
      <p class="tp">在有了角色图之后，有如下的方案：</p>
      <p class="tp"><a style="color: blue;font-size: 15px;">方案1：只购买ai动捕的live2d</a></p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;①.我们将您的人设图转换为ai动捕模型并进行测试，发送给您测试视频 </p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;②.若无质量问题（如模型无法控制嘴、眼睛等），即可进入安装流程，安装完毕则此单结束</p>
      <p class="tp"><a style="color: blue;font-size: 15px;">方案2：只购买手工制作的live2d</a></p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;①.您支付手工live2d的定金，我们将您的人设图进行拆分和建模，发送给您测试视频 </p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;②.若无质量问题，补齐尾款后我们将模型文件发送给您则此单结束</p>
      <p class="tp"><a style="color: blue;font-size: 15px;">方案3：先购买ai动捕看看角色动起来的效果，再考虑要不要制作live2d</a></p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;①.我们将您的人设图转换为ai动捕模型进行测试，发送给您测试视频</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;②.您考虑需不需要进一步制作手工live2d，若不需要制作则仅收您图片制作的费用（自带图则免费），若需要继续做手工live2d模型则参考(方案2)</p>
     <br>
     <br>

     <el-divider><i class="bi bi-brightness-alt-high"></i></el-divider>


    <h2 class="title-container">手工live2d说明:</h2>

    <p class="tp">
      手工live2d就是拆分、建模的live2d，可以导入vts、小k直播姬等平台进行直播，由于其制作时涉及的环节和步骤较多，所以制作工期和价格浮动较大。
    </p>

    <p class="tp">
      一般来说，角色如果较简单，部件数少，则3天左右可以制作完毕，且价格会在价格表的基础上有所降低角色如果比较复杂，或者要求的精细，部件数量多，有较多动画效果，则工期会相应延长，价格也会有所上升。
    </p>

    <br>
    <br>

    <h2 class="title-container2">套餐外附加服务计费说明：</h2>

    <p class="tp">
      由于会有各式各样的修图服务，很难通过一张价格表囊括所有情况，一般情况下我们会根据工作量的多少收取费用，提供部分可以参考的标准：
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 15px;">加个猫耳：</a>0￥（不指定、不定制、好看就行。由于常见，且部件比较大，做起来比较容易，故免费）
    </p>
    
    <p class="tp">
      <a style="  font-weight: bold;font-size: 15px;">加个项链、戒指：</a>30￥（不指定、不定制、好看就行。由于不是很常见，且部件非常小，做起来不容易，一般需要画师参与，故需要收费。）
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 15px;">加个项链，项链指定形状或花纹：</a>30￥ ~ 60￥（指定形状或花纹类的部件若可提供良好清晰的参考图为30，若需要由我们根据文字描述或模糊参考重新制作然后再挑选修改则为60）
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 15px;">增加一个新衣服：</a>60￥ ~ 800￥（仅出图不需要拆分和建模60，需要出图+拆分400，需要出图+拆分+建模500-800（取决于服装复杂程度））
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 15px;">增加一个立式麦克风的图层：</a>100￥ ~ 300￥（少见，必须画师参与，故收费。如果需要定制或者对风格有指定要求，比如必须要摇滚的、必须有什么装饰等需要额外考虑的因素则会加价）
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 15px;">去除ai感：</a>30￥ ~ 120￥（仅使用ai减少光影效果以去除ai感30，人工修改120）
    </p>
    
    
    

    <br>
    <br>

    <h2 class="title-container2">模型制作费用计算方式：</h2>

    <p class="tp">
      模型部件越多、精度越高，可以动的地方就越多，表现力就越强    
    </p>
    <p class="tp">
      默认部件数量需要≤60个，超出数量会在基础价格上略微增加    
    </p>
    <p class="tp">
      模型费用=（套餐基本费用+附加服务）*（实际部件数/60）
    </p>
    <p class="tp">
      模型费用=1365=（1000+50）*（80/60）    
    </p>

    <br>
    <br>

    <h2 class="title-container2">工程文件说明：</h2>

    <p class="tp">
      工程文件默认不提供，如果需要工程文件则需要支付模型同等费用购买    
    </p>

    <br>
    <br>

    <h2 class="title-container2">授权和版权：</h2>
    <p class="tp">
      默认提供商用授权    
    </p>
    <p class="tp">
      本工坊的版权转让为买断形式（即版权卖绝），进行模型（或立绘）的版权买断需要支付额外的费用。同时为避免日后产生纠纷，双方应当签订具有法律效用的版权转让协议（可纸签或网签）或合同。    
    </p>

    <br>
    <br>

    <h2 class="title-container2">关于定金：</h2>
    <p class="tp">
      手工live2d由于普遍偏贵，且制作非常耗费人力，所以需要采取定金+尾款的形式购买    
    </p>
    <p class="tp">
      定金为总金额的50％，人设图草图阶段可全退，若定稿后则不支持全额退款，而是依据已进行的工作量部分退款    
    </p>

    <br>
    <br>

    <h2 class="title-container2">关于违约和逃单：</h2>
    <p class="tp">
      如果因为各种原因无法制作后续的制作费用，请先和客服进行说明，可以延期支付。若拒不支付、耍赖逃单、拒绝支付尾款，工作室有权任意处理、售卖所制作的图片、模型。此时此角色的创意、设定、图片、模型等一切权力均归工作室所有。    
    </p>

    <br>
    <br>


    
    <h2 class="title-container2">手工live2d模型精度说明：</h2>
    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">呼吸：</a>身体跟随呼吸具有节律性的起伏动作
    </p>
    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">眨眼：</a>模型左右眼跟随摄像头动作捕捉，可与真人同步张开/闭上眼睛
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">说话：</a>模型嘴巴可跟随摄像头动作捕捉，与真人同步张开/闭上嘴巴
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">倾斜：</a>模型的头部以及身体向左或向右侧倾，但保持正面朝前，无旋转效果
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">瞳孔跟踪：</a>模型的眼球可跟随摄像头捕捉到的人眼动向同步到模型身上，使得模型的眼球可以在眼眶中游转
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">物理绑定：</a>头发、辫子、飘带、衣襟、长袖、裙摆、胸部……等软性部件可以模拟真实物理惯性产生摆荡效果
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">头部九轴：</a>头部可以向各方向进行一定角度的仿3D扭转效果（中，上，下，左、右、左上、右上、左下、右下） 
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">身体九轴：</a>与头部九轴意思相同，即身体可以左右扭转，以及前探身和轻微后仰。即身体的上下左右方向，以及相对结合以后的另外四个斜向。
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">九种口型：</a>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;默认状态下嘴巴的：闭合、张开、全张</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;微笑状态下嘴巴的：闭合、张开、全张</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;委屈状态下嘴巴的：闭合、张开、全张</p>
      <p class="tp">以上口型可以受到摄像头捕捉并识别，而使得模型的口型变化和过渡更为丰富和多样。比之嘴巴基本的动作仅有“开口/闭口”样式表现力得到大幅度提升。</p>
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">按键表情：</a>即通过键盘特定按键呼出的特定动作。比如头上的汗滴、问号、脸上的红晕。或者隐藏/显示角色的帽子，外衣……等指定部分。或者切换显示不同的五官……等等
    </p>

    <p class="tp">
      <a style="  font-weight: bold;font-size: 20px;">特殊动画：</a>特殊动画指由多个编辑好的动作构成的非特定时长的一段动画。
    </p>

    <br>
    <br>

    <el-divider><i class="bi bi-brightness-alt-high"></i></el-divider>


    <h2 class="title-container">AI动捕的live2d说明：</h2>

    <h2 class="title-container2">AI动捕模型精度说明：</h2>
    <p class="tp">
      由于是ai直出的动捕，目前精度和画师手工的还有差距，比如转头角度过大，ai“猜”不出来的时候边缘会糊，不过价格只有以往手工live2d的五分之一，我们目前也在努力升级模型以达到更好的效果并增加物理效果，软件会自动推送更新，更新推送升级均免费。    
    </p>

    <br>
    <br>
    <h2 class="title-container2">插画生成说明：</h2>
    <p class="tp">
      工作室承接插画生成、Q版表情包生成等业务，插画的精度和水准很高，可以出8k的极大高清图，具体效果可以移步<a href="https://daemao.top/custCase" style="color:blue">客户案例</a>查看,插画可以用来发动态、做直播封面、做壁纸、粉丝福利等等，表情包可以用来售卖、做粉丝主题等等，所生成图片均可自由商用。    
    </p>

    <br>
    <br>
    <h2 class="title-container2">买断与版权说明：</h2>
    <p class="tp">
      由于是ai动捕，并没有手工live2d的工程文件，只有图片和一个模型文件，我们承诺不论是一条龙、自定义人设还是收费成品，均是买断制，不会再售（同时售出后工作室也不会再保存您的文件，注意文件不要丢失），您的模型、图片均可自由商用，如果需要额外的版权证明则需要额外支付一笔办理费用。    
    </p>

    <br>
    <br>
    <h2 class="title-container2">关于定金：</h2>
    <p class="tp">
      AI动捕由于单价较低，没有定金和尾款的规则，在草图阶段不满意可以退全款，若进入了修图阶段则不再支持退款，如发生极特殊情况需要协商退款，我们会根据剩余工作量的多少进行部分退款。对恶意找茬、耍无赖、逃单的行为，并在30日内未得到解决，本工作室有权将所有工作成果进行二次售卖，且不再接受此人委托与合作。    
    </p>





    </div>

    </div>
   </el-col>
    <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"><div><br></div></el-col>




  </div>
  </el-col>
  <el-col :xs="1" :sm="1" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple"></div></el-col>
  </el-row>
</template>


<script>
import PriceTable from '../components/PriceTableContent.vue'

export default {
  name: 'miniprice',
  components: {
    PriceTable,
  }
}

</script>

<style>
.row-high{
  margin-top: 40px; /* 设置上边距为 50px，可以根据需要调整数值 */
}
.title-container {
  display: flex;
  /* justify-content: center; */
  text-align: center;
  font-size: 40px; /* 4% of viewport width */
  font-family: '微软雅黑';
  color: white; 
  font-weight: 700; 
  margin: 0px; 
  padding: 0px; 
  display: inline-block; 
  text-shadow: rgb(0, 0, 0) 1px 0px 4px, rgb(0, 0, 0) 0px 1px 4px, rgb(0, 0, 0) 0px -1px 4px, rgb(0, 0, 0) -1px 0px 4px;
}

.title-container2 {
  display: flex;
  /* justify-content: center; */
  text-align: center;
  font-size: 20px; /* 4% of viewport width */
  color : rgb(0, 0, 0);
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}


.tp {
  /* display: flex; */
  margin-top: 10px;
  font-size: 15px; /* 4% of viewport width */
}
</style>


