<template>
    <div class="row-high">
      <br>
      <div class="button-wrapper">
        <router-link to="/store_sec" class="button">进入设计区</router-link>
        <router-link to="/store_fir" class="button">进入精图区</router-link>
      </div>
      <router-view></router-view>
      <el-divider><i class="bi bi-cloud-moon"></i></el-divider>
      <radar-chart></radar-chart>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="flex-row">
          <el-col>
          <el-col>
            <!-- 第1个图片 -->
            <a style="font-size: large;  display: flex;  justify-content: center;  align-items: center;  flex-direction: column; color: red;">设计区：</a>
            工作室精选角色，角色更具设计感，最终处理后具有优秀设计感和充分细节
            <el-image src="https://s2.x914.com/daemao/i/%E5%8D%8A%E6%88%90%E5%93%81%E5%BA%93%E8%AF%B4%E6%98%8E/1.png"></el-image>
          </el-col>
          <el-col>
            <!-- 第2个图片 -->
            <a style="font-size: large;  display: flex;  justify-content: center;  align-items: center;  flex-direction: column;"></a>
            <el-image src="https://s2.x914.com/daemao/i/%E5%8D%8A%E6%88%90%E5%93%81%E5%BA%93%E8%AF%B4%E6%98%8E/2.png"></el-image>
          </el-col>
        </el-col>


        <el-col>
          <el-col>
            <!-- 第3个图片 -->
            <a style="font-size: large;  display: flex;  justify-content: center;  align-items: center;  flex-direction: column; color: blue;">精图区：</a>
            拥有更多选择，千余款角色可选，处理后具有良好设计感和充分细节 
            <el-image src="https://s2.x914.com/daemao/i/%E5%8D%8A%E6%88%90%E5%93%81%E5%BA%93%E8%AF%B4%E6%98%8E/3.jpg"></el-image>
          </el-col>
          <el-col>
            <!-- 第4个图片 -->
            <a style="font-size: large;  display: flex;  justify-content: center;  align-items: center;  flex-direction: column;"></a>
            <el-image src="https://s2.x914.com/daemao/i/%E5%8D%8A%E6%88%90%E5%93%81%E5%BA%93%E8%AF%B4%E6%98%8E/4.jpg"></el-image>
          </el-col>
        </el-col>

          </div>
        </el-col>
        <el-col>

        </el-col>
      </el-row>
      <el-divider><i class="bi bi-cloud-moon"></i></el-divider>
      <div class="button-wrapper">
        <router-link to="/store_sec" class="button">进入设计区</router-link>
        <router-link to="/store_fir" class="button">进入精图区</router-link>
      </div>
    </div>
</template>

<script>
import RadarChart from "@/components/RadarChart.vue";

export default {
  components: {
    RadarChart,
  },
};
</script>

<style>
.row-high{
margin-top: 70px; /* 设置上边距为 50px，可以根据需要调整数值 */
}
.button-wrapper {
  display: flex;
  justify-content: center;
  gap: 50px; /* 设置间隔 */
}

.button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #F2F6FC;
  border-radius: 5px;
  text-decoration: none;
  color: #000000;
}

.button:hover {
  background-color: #d7d7d7;
}
</style>