<template>
    <div class="image-frame">
    
      <div class="image-grid">

        <div class="block">
            <span class="demonstration">默认表情</span>
            <el-image :src="src1">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">得意</span>
            <el-image :src="src2">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">不开心</span>
            <el-image :src="src3">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>        
        
        <div class="block">
            <span class="demonstration">生气</span>
            <el-image :src="src4">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">开心</span>
            <el-image :src="src5">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">翻白眼</span>
            <el-image :src="src6">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">和蔼</span>
            <el-image :src="src7">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">震惊</span>
            <el-image :src="src8">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">看向一边</span>
            <el-image :src="src9">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">升天</span>
            <el-image :src="src10">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">无语</span>
            <el-image :src="src11">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>

        <div class="block">
            <span class="demonstration">嫌弃</span>
            <el-image :src="src12">
            <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
            </div>
            </el-image>
        </div>
      </div>
    </div>  
    </template>
    
    <script>
    export default {
      data() {
        return {
            src1: 'https://s1.imagehub.cc/images/2023/07/15/1.png',
            src2: 'https://s1.imagehub.cc/images/2023/07/15/2.png',
            src3:  'https://s1.imagehub.cc/images/2023/07/15/3.png',
            src4:  'https://s1.imagehub.cc/images/2023/07/15/4.png',
            src5:  'https://s1.imagehub.cc/images/2023/07/15/5.png',
            src6: 'https://s1.imagehub.cc/images/2023/07/15/6.png',
            src7: 'https://s1.imagehub.cc/images/2023/07/15/7.png',
            src8:  'https://s1.imagehub.cc/images/2023/07/15/8.png',
            src9:  'https://s1.imagehub.cc/images/2023/07/15/9.png',
            src10:  'https://s1.imagehub.cc/images/2023/07/15/10.png',
            src11: 'https://s1.imagehub.cc/images/2023/07/15/11.png',
            src12:  'https://s1.imagehub.cc/images/2023/07/15/12.png',

        }
      }
    }  
    </script>
  
  <style>
  .image-frame {
    width: 100%;
    height: 100%;
    
    border-radius: 10px;
    border: 1px solid #ddd;
    background: rgba(255,255,255,0.5);
    backdrop-filter: blur(10px);
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-grid {
    width: 80%;
    height: 80%;
    padding: 20px;
  
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 10px;
  }
  
  @media (max-width: 650px) {
    .image-grid {
      width: 90%;
      height: 90%; 
    }
  }
  
  .image-item {
    grid-row: auto / span 1; 
    grid-column: auto / span 1;
  }
  .image-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  </style>