<template>
  <div class="price_value-table">
          <div v-for="title in titles" >
           <br>
           <br>
           <br>
                  <el-table
                  :data="filterTableData(title)"
                  >
                          <el-table-column
                          prop="price_name"
                          :label="'服务项目:   ' + title "
                          width="150"
                          column-key="price_name"
                          :min-width="10"
                          >
                          </el-table-column>

                          <el-table-column label="价格" :min-width="50">
                            <template slot-scope="scope">
                              <span v-if="scope.row.tag === '特价'">
                                <span class="origin-price">{{scope.row.price_value}}</span>
                                <span style="margin: 0 5px;"></span>
                                <span class="special-price">{{scope.row.tag_price}}</span>
                              </span>
                              <span v-else>
                                {{scope.row.price_value}}
                              </span>
                            </template>
                          </el-table-column>

                          <el-table-column
                          prop="note"
                          label="描述"
                          :formatter="formatter"
                          >
                          </el-table-column>

                      
                          <el-table-column
                          prop="tag"
                          label="可选标签"
                          :filters="tagFilters"
                          :filter-method="filterTag"
                          filter-placement="bottom-end"
                          :min-width="40"
                          >
                          <template slot-scope="scope">
                              <el-tag
                              :type="scope.row.tag === '原价' ? 'primary' : 'success'"
                              disable-transitions
                              >{{scope.row.tag}}</el-tag>
                          </template>
                          </el-table-column>



                  </el-table>
          </div>
  </div>
</template>



<script>
import axios from 'axios'

export default {
  created() {
},
  components: {
    
  },
  data() {
    return {
      tableData: [
      ],
      openedTables: this.titles,// 直接赋值为全部标题

      tagFilters: [
        { text: '特价', value: '特价' },
        { text: '原价', value: '原价' },
      ],
    };
  },
  mounted() {
  axios.get( this.$store.state.baseURL+'/api/data')
    .then(response => {
      this.tableData = response.data
      // console.log(response.data)
    })


},
  computed: {
    titles() {
    const titles = ['AI动画', '手工live2d', 'AI动捕', '插画', '修图']

    const exists = {}

    this.tableData.forEach(item => {
      if (!exists[item.title]) {
        exists[item.title] = true
      }
    })

    // 过滤不存在的标题
    return titles.filter(t => exists[t])
  }
},
  methods: {
      filterTableData(title) {
          return this.tableData.filter(item => item.title === title)
  },
    formatter(row, column) {
      return row.note;
    },
    filterTag(value, row) {
      return row.tag === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
  },

};

</script>

<style>
.special-price {
  font-weight: bold;
  color: red;
}
.origin-price {
  text-decoration: line-through;
  color: #999;
}

.el-table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  width: 100%;
  max-width: 100%; 
  overflow: hidden;
}

@media not all and (min-resolution:.001dpcm) { 
  .el-table {
    font-size: 16px;
  }
}


.el-table-container {
  transform: scale(1.1) 
}
</style>