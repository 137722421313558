<template>
    <el-row :gutter="10">
    <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple"></div></el-col>
    <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">
        <div>
        <el-table
            class="row-high"
            :data="tableData"
            style="width: 100%"
            :row-class-name="tableRowClassName">
            
            <el-table-column
                prop="address"
                label="常见部分：Q & A">
            </el-table-column>
            </el-table>
        </div>
    </el-col>
    <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
  </template>
  

  
<script>
    export default {
      name: 'afterSales',
      methods: {
        tableRowClassName({row, rowIndex}) {
          if (rowIndex % 2 !== 0) {
            return 'success-row';
          } 
          return '';
        }
      },
      data() {
        return {
          tableData: [{
            address: 'Q:AI目前能做到什么程度？',
          }, {

            address: 'A:我们的 live2d 是 AI 生成和动捕的，可控制眉毛、嘴巴、眼睛、头部、肩部、歪头、侧身和倾斜等动作，可快捷键键切换表情，满足基础直播的所有功能。'
          }, {
            address: 'Q:可以做手工live2d吗？',
          }, {

            address: 'A:也可以做手工live2d的，而且价格物美价廉哦！'
          },{

            address: 'Q：我现在电脑配置不够/不在身边，可以在活动期间先买了，以后需要的时候再要求你们安装吗？',
          }, {

            address: 'A:没有问题，您提供订单号给客服，售后会在您方便的时候协助安装。'
          },{

            address: 'Q:免费客户能享受免费安装之类的待遇吗？',
          },{
            
            address: 'A:由于人力有限，我们会为免费客户提供完整的软件、教程以及必要的说明，但无法协助您进行安装，敬请谅解。',
          },{
            
            address: 'Q:手部可以动吗？',
          },{
            
            address: 'A:暂时不支持，未来有计划升级。如有需要可以选购手工live2d。',
          },{
            
            address: 'Q:live2d工期多久？',
          },{
            
            address: 'A:AI动捕的工期是三个工作日，非特殊情况一般第二天就会给草图，手工Live2d的工期在5-15个工作日左右。',
          },{
            
            address: 'Q:送哪些表情？',
          },{
            
            address: 'A:赠送11个表情（开心、不开心、得意、生气、翻白眼、惊讶、温柔、无语、升天、嫌弃、看向一侧），此外也可以使用软件自捏表情。',
          },{
            
            address: 'Q:有拆分或者moc文件吗？支持导入vts、小K直播姬吗？',
          },{
            
            address: 'A:由于是ai直接动捕，不需要拆分，故也没有moc文件，不支持导入vts或小K直播姬，可通过我们的软件直接进行直播',
          },{
            
            address: 'Q:显卡要求什么样的？',
          },{
            
            address: 'A:显卡需要GTX1650及以上',
          },{
            
            address: 'Q:免费的是买断制，可以随便商用吗？',
          },{
            
            address: 'A:免费客户并不是买断制，我们会定期更换模型，持续提供不同的免费模型，但不承诺免费模型的买断和商用权力。',
          },
        
        ]
        }
      }
    }
  </script>


<style>
.row-high{
margin-top: 40px; /* 设置上边距为 50px，可以根据需要调整数值 */
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table {
  font-size: 16px;
}
</style>