<template>
    <div class="bg-gray-900 text-gray-100 py-3.5 px-6 shawdow md:flex justify-between items-center ">
        <div class="flex items-center cursor-pointer">
            <span class="text-green-500 text-xl mr-1">
                <i class="bi bi-yelp"></i>
            </span>
            <h1 class="text-xl">大鹅猫工作室</h1>

        </div>

        <!-- <span @click="MenuOpen()" class="absolute md:hidden right-6 top-1.5 cursor-pointer text-4xl">
            <i :class="[open ? 'bi bi-x' : 'bi bi-filter-left' ]"></i>
        </span> -->
        <span @click="MenuOpen()" class="absolute md:hidden right-3 top-3 cursor-pointer text-2xl">
            {{ open ? '关闭' : '导航' }} 
        </span>

        <ul class="md:flex md:items-center md:px-0 px-3 md:pb-0 pb-10 md:static absolute
        bg-gray-900 md:w-auto  top-14 duration-700 easy-in"
        :class="[open ? 'right-0' : 'right-[-100%]']"
        >
            <li class="md:mx-4 md:my-0 my-6" v-for="link in Links" @click="MenuOpen()">
                <router-link :to="link.link" class="text-xl hover:text-green-500">{{ link.name }}</router-link>
            </li>
            <li class="md:mx-4 md:my-0 my-6">
                <a href="https://live2dshop.com/seller/75" class="text-xl hover:text-green-500">精品成品库</a>
            </li>
            <Button to="/about" :classes="['bg-green-400 hover:bg-green-500 duration-300 font-sm text-white rounded py-1.5 px-4']">
                点我购买
            </Button>
            <!-- <Button to="/test" :classes="['bg-bule-400 hover:bg-green-500 duration-300 font-sm text-white rounded py-1.5 px-4']">
                测试
            </Button> -->
        </ul>
    </div>
</template>

<script>
import { ref } from 'vue';
import Button from '../components/Button.vue';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
    data() {
    return {
        open: false
    }  
    },
    methods: {
    MenuOpen() {
        this.open = !this.open
    }
    },
    components: {
        Button
    },
    name: "Navbar",
    setup() {
        let open = ref(true);
        
        let Links = [
            { name: "首页", link: "/" },
            { name: "价格表", link: "/price" },
            { name: "半成品库", link: "/store" },
            // { name: "画风库", link: "/paintingquet" },
            { name: "背景库", link: "/backquet" },
            { name: "客户案例", link: "/custCase" },
            // { name: "精品成品库", link: "/fineStore" },
            // { name: "售后服务", link: "/afterSales" },
            // { name: "常见问题", link: "/frequentlyQus" },
            { name: "客户退款", link: "/freeRefund" },
            // { name: "点我购买", link: "/about" },
        ]

        function MenuOpen() {
            open.value = !open.value
        }

        return {Links,open,MenuOpen}
    }
}
</script>