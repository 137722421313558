<!-- store_sec.vue nai3成品库-->
<template>
    <div class="row-high">
        <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">
          <el-divider><i class="bi bi-cloud-moon"></i></el-divider>
          <div class="button-wrapper">
            <router-link to="/store_sec" class="button">进入设计区</router-link>
            <router-link to="/store_fir" class="button">进入精图区</router-link>
          </div>
          <div class="floating-button" @click="backToContainer"></div>
            <p>
              <a style="color:red">设计区</a>
              <br>
              1.展示图仅为角色大体形象，<a style="color:red">并非最终成图</a>，可进行去除杂物、微调、修图、增加挂件、调色等,，可以点击<a style="color:blue">查看详情</a>查看细化后的大致参考图【若没有可以联系客服<a style="color:red">免费制作!</a>】
              <br>
              <br>
              2.<a style="color:blue">设计区</a>的展示图仅为角色设计<a style="color:red">草图</a>，会先对其进行全图细化
              <br>
              <br>
              3.<a style="color:blue">设计区图的设计质感最强，但由于需要处理的环节较多，价格相比精图区稍贵</a>
              <br>
              设计区的角色图设计感较强，我们会在保持整体设计感、维持原有形状和大致配色的情况下细化角色（注意，默认套餐并不包含大的结构改动），也可以修复一些不合理的部件（包括更换衣服纹理等）、摆正角色等
              <br>
              <br>
              <div class="demo-image__placeholder">
                <div class="block">
                  <span class="demonstration">全图细化+掰正</span>
                  <el-image :src="src_store_sec">
                    <div slot="placeholder" class="image-slot">
                      加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <div class="block">
                  <span class="demonstration">全图细化</span>
                  <el-image :src="src_store_sec2">
                    <div slot="placeholder" class="image-slot">
                      加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
              </div>
              <br>
              <br>
              4.半成品为买断制，您购买后我们会下架模型不再二售。
              <br>
              <br>
              5.大鹅猫工作室欢迎您给喜欢图片点赞，帮助我们优化模型，我们衷心希望可以与您一同成长！🐣
              <br>
              <br>
              6.免费皮套分享压缩包解压密码：daemao.top
              <p class="chatP">点击下方任意链接，均可以直接购买（淘宝有专属客服，购买体验更好哦~）：</p>
              <p><a href="https://item.taobao.com/item.htm?ft=t&id=702345281626&spm=a21dvs.23580594.0.0.52de3d0d4wL978" style="color:blue">淘宝</a></p>
              <p><a href="https://afdian.net/a/daemao" style="color:blue">爱发电</a></p>
              <p><a href="https://daemao.huotan.com/" style="color:blue">货摊网</a></p>
            </p>
            <br>
            <div>

              <div class="input-btn-container">
                <div class="left-container">
                  按图片id搜索：<input type="text" v-model="searchId" placeholder="输入ID,点击enter进行搜索" class="search-input"/>
                  <button @click="resetIdsearch">重置id搜索</button>
                </div>
                <div class="right-container">
                  <!-- <el-button icon="el-icon-search" @click="drawer = true" class="search-btn" style="color: #409EFF;background: #F2F6FC;">启用高级搜索</el-button> -->
                  <button type="button"
                          @click="drawer = true"
                          class="px-5 py-2.5 rounded-lg text-white text-sm tracking-wider font-medium border border-current outline-none bg-green-400 hover:bg-green-600 active:bg-green-400"
                  >
                  启用搜索  
                  </button>
                </div>
              </div>

              <el-drawer
                :visible.sync="drawer"
                :direction="direction"
                :before-close="handleClose"
                :size="'60%'"
              >
                <div class="drawer-header">
                  <div class="drawer-title">选择高级搜索</div>
                  <div class="close-button">
                    <i class="el-icon-error" @click="handleClose"></i>
                  </div>
                </div>
                <search @search-results="handleSearchResults"  @close-drawer="handleCloseDrawer"></search>
              </el-drawer>

            </div>


            <el-divider><i class="bi bi-brightness-alt-high"></i></el-divider>

            <div class="grid-content" style="display:flex;justify-content:space-between;">
                <el-checkbox v-model="man">男</el-checkbox> 
                <el-checkbox v-model="woman">女</el-checkbox>
                <el-checkbox v-model="is_cheap">特价推荐</el-checkbox>
                <el-checkbox v-model="randomlook"  @change="handleChange">随机看看</el-checkbox>
                <el-button v-if="randomlook"  size="mini"  @click="changeRandomImages">下一波</el-button>

                <el-dropdown>
                  <span class="el-dropdown-link">
                    {{ getDropdownButtonText() }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="sortByThumbsUp">大众点赞数排序</el-dropdown-item>
                    <!-- <el-dropdown-item @click.native="changeRecommend(true)">daemao推荐人设</el-dropdown-item> -->
                    <el-dropdown-item @click.native="resetSortAndRecommend">重置</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown size="mini">
                  <span class="el-dropdown-link">{{ ageText }} <i class="el-icon-arrow-down el-icon--right"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="ageDefaults">选取所有年龄</el-dropdown-item>
                    <el-dropdown-item v-for="item in resultsage" :key="item" @click.native="selectAgeOption(item)">
                      {{ item }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-button type="primary" plain @click="resetDefaults" size="mini">重置所有选择</el-button>

                <ImageGrid v-if="randomlook" :images="randomImages" :showDetailButton="true"/>

                <ImageGrid v-else :images="currentPageData" :showDetailButton="true" :showVideo="false"   :showWatermark="false" :showthumbsUp="true" @lora-updated="handleArtStyleSelection"/>
                
                <el-row justify="center">

                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                      <el-button @click="prevPage">上一页</el-button>
                    </el-col>

                    <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">  
                        <el-input 
                          v-model.number="currentPage"
                          @change="handlePageChange"
                          type="number" 
                          min=1    
                          placeholder="请输入页码"
                          clearable
                          />
                    </el-col>

                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                      <el-button @click="nextPage">下一页</el-button>
                    </el-col> 
              </el-row>
              <p style="font-size: 15px;text-align: center;">输入框内可输入页码，总页数为：{{ totalPages }}</p>



            </div>
        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        </el-row>
    </div>
</template>


<script>
import axios from 'axios';
import formFree from '../components/formFree.vue';
import ImageGrid from '@/components/ImageGrid.vue';
import watermark from '@/components/watermark.vue';
import search from '@/components/search.vue';


  export default {
      computed: {

      filteredImages() {
        // 添加计算属性,过滤图片
        let images = this.images.filter(i => i.is_sold !== 1 && i.bigmodel === 'NAI3');

        // 添加根据 ID 进行筛选的逻辑，只有当 searchId 不为 null 时才进行筛选
        if (this.searchId !== null) {
          images = images.filter(i => i.id === parseInt(this.searchId));
        }

        // 根据下拉框的选择的选项筛选图片
        if (this.selectedModelName) {
          images = images.filter(i => i.modelName === this.selectedModelName);
        }

        // 根据下拉框的选择的选项筛选图片
        if (this.selectedModelNamelora) {
          images = images.filter(i => i.lora === this.selectedModelNamelora);
        }

        // 年龄筛选
        if (this.selectedage) {
          images = images.filter(i => i.age === this.selectedage);
        }

        if (this.recommend) {
          images = images.filter(i => i.recommend === 1) 
        }
        if (!this.man) {
          images = images.filter(i => i.type !== '男');
        }

        if (!this.woman) {
          images = images.filter(i => i.type !== '女'); 
        }

        if (this.is_cheap) {
          images = images.filter(i => i.is_cheap == 1); 
        }

        if (this.sortByThumbsUpFlag) {
          // 根据 thumbsup 从大到小排序
          images.sort((a, b) => b.thumbsup - a.thumbsup);
        }

          // 计算总页数
        this.totalPages = Math.floor(images.length / this.pageSize + 1)

        return images;
      },

      currentPageData() { 
        // 从 filteredImages 中按分页取数据
        const start = (this.currentPage - 1) * this.pageSize
        const end = start + this.pageSize
        return this.filteredImages.slice(start, end)
      },
      
    },

    created() {
      // console.log('请求发送前')

      // 取到成品库图片位置url和图片信息
      axios.get(this.$store.state.baseURL+'/api/dataKu')
        .then(response => {
          this.images = response.data
          // console.log(response.data[0]);
        })
        // console.log('请求发送后')

      // 取到大模型的自定义名称 
      axios.get(this.$store.state.baseURL + '/api/resultsmodelName')
      .then(response => {
        this.resultsmodelName = response.data;
        // console.log(this.resultsmodelName) 
      })
      .catch(error => {
        console.error(error);
      });

      // 取到画风自定义名称 
      axios.get(this.$store.state.baseURL + '/api/resultsmodelNamelora')
      .then(response => {
        this.resultsmodelNamelora = response.data;
        // console.log(this.resultsmodelNamelora) 
      })
      .catch(error => {
        console.error(error);
      });


      

    },

    methods: {
      handleSearchResults(results) {
        // 将search-results的值赋给this.images
        // console.log('Received search-results value:', results);
        this.images = results;
      },
      handleCloseDrawer(value) {
        // 将close-drawer的值赋给this.drawer
        // console.log('Received close-drawer value:', value);
        this.drawer = value;
      },

      handleArtStyleSelection(value) {
        // 子组件的子组件传递过来的有关当前图片的lora画风信息
        console.log('Received lora2 value:', value);  

        this.resetDefaults();
        
        this.selectedModelNamelora = value;
        // console.log(this.selectedModelNamelora)
        this.loraText = '同类画风';
        // 优先画风选择，重置大模型选择
        this.selectedModelName = null;
        this.dropdownText = "按照画风选择";
        this.currentPage = 1;
      },

      handleChange() {
        // 勾选就开始随机有图片
      if (this.randomlook) {
        this.changeRandomImages();
      }
    },
      changeRandomImages() {
      // 随机选图片拷贝
      let images = this.images.filter(i => i.is_sold !== 1 && i.bigmodel === 'NAI3')
      // console.log(images)
      // let randomData = JSON.parse(JSON.stringify(images.slice(0, -1000)));
      let randomData = JSON.parse(JSON.stringify(images));
        if (!this.man) {
          randomData = randomData.filter(i => i.type !== '男');
        }

        if (!this.woman) {
          randomData = randomData.filter(i => i.type !== '女'); 
        }

        if (this.is_cheap) {
          randomData = randomData.filter(i => i.is_cheap == 1); 
        }

        // 使用 Fisher-Yates 算法打乱数组顺序
        for (let i = randomData.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [randomData[i], randomData[j]] = [randomData[j], randomData[i]];
        }
        
      // 随机排序
      this.randomImages = randomData.slice(0, 24);
    },

      
      getDropdownButtonText() {
        if (this.sortByThumbsUpFlag) {
          return "大众点赞数排序";
        // } else if (this.recommend) {
        //   return "daemao推荐人设";
        } else {
          return "推荐人设";
        }
      },

      sortByThumbsUp() {
        this.resetSortAndRecommend();
        this.sortByThumbsUpFlag = true;
        // console.log(this.sortByThumbsUpFlag)
      },
      changeRecommend(value) {
        this.resetSortAndRecommend();
        this.recommend = value;
      },
      resetSortAndRecommend() {
        this.sortByThumbsUpFlag = false;
        this.recommend = false;
      },
      //重置图片id选择
      resetIdsearch() {
        this.searchId = null;
      },
      // 重置大模型选择
      modelDefaults(){
        this.selectedModelName = null;
        this.dropdownText = "按照模型选择";
      },
      // 重置画风选择
      modelDefaultslora(){
        this.selectedModelNamelora = null;
        this.loraText = "按照画风选择";
      },
      // 重置年龄选择
      ageDefaults(){
        this.selectedage = null;
        this.ageText = "按照年龄选择";
      },
      // 重置所有选项
        resetDefaults() {
          axios.get(this.$store.state.baseURL+'/api/dataKu')
            .then(response => {
              this.images = response.data;
              // 这里的数据正确
              // console.log(response.data);
            })
            .catch(error => {
              console.error(error);
            });
          this.man = true;
          this.woman = true;
          this.is_cheap = false;
          this.recommend = false;
          this.randomlook = false;
          // this.toll = true;
          // this.gratis = false;
          // this.descdata = true;
          this.selectedModelName = null;
          this.selectedModelNamelora = null;
          this.selectedage = null;
          this.dropdownText = "按照模型选择";
          this.loraText = "按照画风选择";
          this.ageText = "按照年龄选择";
          this.resetSortAndRecommend();
      },


      handlePageChange(page) {
        if (page > this.totalPages) {
          this.currentPage = this.totalPages
        }
      } ,
      // scrollToTop() {
      //   document.documentElement.scrollIntoView({
      //     behavior: 'smooth' 
      //   })
      // },
      // descChanged() {
      //   this.currentPage = 1  
      // },
      nextPage() {
        if(this.currentPage >= 1) {
          this.currentPage++ 
        }
        // this.scrollToTop() 
        this.backToContainer()
      },

      prevPage() {
        if(this.currentPage > 1) {
          this.currentPage--  
        }
        // this.scrollToTop() 
        this.backToContainer()
      } ,

      handleClose() {
        // this.$confirm('确认关闭？')
        //   .then(_ => {
        //     done();
        //   })
        //   .catch(_ => {});
        this.drawer = false
      },
      handleClose_box() {
          this.drawer_free_box = false;
        },

      // 筛选同名字的大模型
      selectOption(option) {
          this.selectedModelName = option.ourName;
          this.dropdownText = option.ourName;
          // 重置大模型选择
          this.selectedModelNamelora = null;
          this.loraText = "按照画风选择";
          this.currentPage = 1;
        },

      // 筛选同画风的模型
      selectloraOption(option) {
          this.selectedModelNamelora = option.originalNamelora;
          // console.log(this.selectedModelNamelora)
          this.loraText = option.ourNamelora;
          // 优先画风选择，重置大模型选择
          this.selectedModelName = null;
          this.dropdownText = "按照模型选择";
          this.currentPage = 1;
      },

      // 筛选年龄的图片
      selectAgeOption(age) {
          this.selectedage = age;
          this.ageText = age;
          this.currentPage = 1;
        },

      // 上滑动浮动按钮
      backToContainer() {
          const container = document.querySelector('.input-btn-container');
          if (container) {
          window.scrollTo({
            top: container.offsetTop,
            behavior: 'smooth'
          });
          }
        },

    },
    components: {
        search,
        formFree,
        ImageGrid,
        watermark,
    },
    data() {
      return {
        src_store_sec: 'https://s2.x914.com/daemao/i/%E5%8D%8A%E6%88%90%E5%93%81%E5%BA%93%E8%AF%B4%E6%98%8E/1.png',
        src_store_sec2: 'https://s2.x914.com/daemao/i/%E5%8D%8A%E6%88%90%E5%93%81%E5%BA%93%E8%AF%B4%E6%98%8E/2.png',
        sortByThumbsUpFlag: false, // 标识是否按照点赞数排序
        drawer_free_box: false,  // 免费模型提醒
        images: [],
        resultsmodelName: [],   // 大模型的所有自定义名称
        resultsmodelNamelora: [],   // 画风模型的所有自定义名称
        resultsage: ['q版','小朋友','青年','成男成女','人外'],         // 所有自定义年龄

        searchId: null,         // 搜索某id图片
        selectedModelName: null, // 大画风模型当前搜索的名称
        selectedModelNamelora: null, // 画风模型当前搜索的名称
        selectedage: null,      // 当前搜索的年龄名称
        searchbigmodel: null,   // 半成品库nai3

        man: true,
        woman: true,
        is_cheap: false,
        recommend: false,
        // toll: true,
        // gratis: false,
        // onlyVideo: false,
        randomlook:false,  //随机挑选
        randomImages: [],
        // descdata: true,

        drawer: false,
        direction: 'ttb',
        dropdownText: "按照模型选择",
        loraText:"按照画风选择",
        ageText: "按照年龄选择",


        currentPage: 1, //分页
        pageSize: 39,
        totalPages: 0,
      };
    }
  };
</script>



<style>
.row-high{
margin-top: 50px; /* 设置上边距为 50px，可以根据需要调整数值 */
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

.el-icon-arrow-down {
  font-size: 12px;
}

.search-input {
  border: 1px solid black;
}

.drawer-header {
  display: flex;
  align-items: center;
}
.drawer-title {
  flex-grow: 1;
}
.close-button {
  margin-left: 160px;
}



.input-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-container {
  display: flex;
  align-items: center;
}

.right-container {
  margin-left: 10px; /* 添加适当的外边距，用于与左侧内容分隔 */
}

/* 在小屏幕下改变布局方式 */
@media (max-width: 768px) {
  .input-btn-container {
    flex-direction: column;
  }

  .left-container,
  .right-container {
    margin-bottom: 10px; /* 添加适当的间距，用于上下排列 */
  }
}


/* 悬浮按钮样式 */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: #409EFF;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9999; /* 浮动在最上层 */
}

.floating-button:before {
  content: "";
  position: absolute;
  top: 30%;
  left: 33%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  transform: rotate(45deg);
}
</style>