<template>
  <div> 
    <Navbar class="navbar"></Navbar>
    <Live2d></Live2d>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './views/Navbar.vue';
import Live2d from '../src/components/live2d/index.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Live2d,
  }
}
</script>


<style>
.navbar {
  position: fixed; /* 使用固定定位 */
  top: 0; /* 将导航栏置于页面顶部 */
  left: 0; /* 将导航栏置于页面最左侧 */
  width: 100%; /* 设置导航栏的宽度为100% */
  z-index: 9999; /* 设置导航栏的层叠顺序，保证它在最上方显示 */
}
</style>
