import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // ...
  state: {
    // baseURL: 'http://127.0.0.1:5050'
    // baseURL: 'https://daemao.top:5000'
    baseURL: 'https://daemao.cloud'
  }
})  