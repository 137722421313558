<template>
    <div class="row-high">
      <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">
          <p>视频数据量较大，请耐心等待</p>
          <el-card v-for="item in data" :key="item.id">
            <videoFinestore :video-url="item.videourl"></videoFinestore>
            <el-button plain @click="openDocxUrl(item.docxurl)" class="mt-2">点击查看更多</el-button>
            <!-- <el-divider></el-divider>  -->
          </el-card>
          
          <el-card>
            <GroupChat></GroupChat>
          </el-card>

        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
      </el-row>
    </div>
  </template>



<script>
import GroupChat from '@/components/GroupChat.vue';
import axios from 'axios';
import videoFinestore from '@/components/videoFinestore.vue';

export default {
  components: {
    videoFinestore,
    GroupChat
  },
  data() {
    return {
      data: []
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(this.$store.state.baseURL+'/api/finestorevideos')  // 更改为实际的后端路由
        .then(response => {
          this.data = response.data;
        //   console.log(this.data)
        })
        .catch(error => {
          console.error(error);
        });
    },
    openDocxUrl(docxurl) {
      window.open(docxurl);
    }
  }
  }
</script>


<style>
.row-high{
margin-top: 150px; /* 设置上边距为 50px，可以根据需要调整数值 */
}

.mt-4 {
  margin-top: 16px; /* 根据需求调整上边距的数值 */
}

</style>