<template>
<div >
    <mainLoop ></mainLoop>
</div>  
</template>

<script>
import mainLoop from './mainLoop.vue'

export default {
  name: 'HomeView',
  components: {
    mainLoop,
  }
}
</script>

<style>

</style>