<!-- Detail.vue -->
<template>

<div  class="row-high">
        
        <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="3" :xl="3"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="18" :xl="18">
            <div 
            class="image-wrap"
            v-for="image in images"
            :key="image.id"
            >

            <img :src="image.src">
            <el-divider></el-divider>
            </div>

        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="3" :xl="3"><div class="grid-content"></div></el-col>
        </el-row>
</div>

</template>
  
  <script>
  export default {
    name: 'Detail',
  
    props: ['images'],
  
    data() {
      return {
        back: null 
      }
    },
  
  }
  </script>
  
  <style>
  .row-high{
    margin-top: 10px; /* 设置上边距为 50px，可以根据需要调整数值 */
    }

  .image-wrap {
  margin-bottom: 16px;
}
  </style>