<template>
    <div>
      <el-switch
        style="display: block;display: block; margin: 10px 0"
        v-model="fuzzySearch"
        active-text="模糊搜索开启"
        inactive-text="模糊搜索关闭"
      >
      </el-switch>

      <el-switch
        style="display: block;display: block; margin: 10px 0"
        v-model="value2"
        active-text="按tag搜索图"
        inactive-text="以图搜图">
      </el-switch>
  
      <div v-if="value2" class="tag-container">
        <!-- 活动tag -->
        <div v-for="tag in tags" :key="tag.value" class="tag"
          :class="{ selected: selectedTags.includes(tag.value) }" @click="toggleTag(tag.value)">
          {{ tag.text }}
          <span v-if="selectedTags.includes(tag.value)" class="tag-remove"
            @click.stop="removeTag(tag.value)">×</span>
        </div>
        
        <!-- 可以下拉tag -->
        <div v-for="adjustableTag in adjustableTags" :key="adjustableTag.value" class="tag selectable"
          :class="{ selected: selectedAdjustableTags[adjustableTag.value] }">
          <select v-model="selectedAdjustableTags[adjustableTag.value]"
            @change="updateAdjustableTag(adjustableTag.value)">
            <option v-for="option in adjustableTag.options" :value="option.value" :key="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
  
        <div class="search-button-container">
          <button class="search-button" @click="submitSearch">搜索</button>
        </div>
      </div>
  
    
      <div  v-if="!value2"  id="app">
        <label for="file-upload" class="upload-btn">
          <input
            type="file"
            name="image"
            id="file-upload"
            accept="image/*"
            @change="uploadImage"
            style="display: none;"
          />
          <span>上传图片</span>
        </label>
        <div class="loading" v-if="loading">
          <div class="loading-spinner"></div>
          <p>用户上传火热，服务器加急处理，请耐心等待...</p>
        </div>
        <div v-if="uploadError" class="error-message">
          <p>服务器满载，请稍后再试。</p>
        </div>
      </div>

    </div>
  </template>

<script>
import axios from 'axios';

  export default {
    data() {
      return {
        fuzzySearch: false,  // 模糊搜索
        uploadError: false, // 新增一个uploadError属性
        loading: false, // 初始值设置为false，即不显示loading
        value2: true,
        value: 0,
        tags: [],
        adjustableTags:[],
        selectedTags: [],
        selectedAdjustableTags: {}
      };
    },
    created() {
      axios.get(this.$store.state.baseURL + '/api/searchtag')
      .then(response => {
        this.tags =response.data.search_tag
        this.adjustableTags = response.data.adjustable_tags
        this.adjustableTags.forEach(tag => {
        this.$set(this.selectedAdjustableTags, tag.value, tag.options[0].value);
      });
        // console.log(this.tags) 
      })
      .catch(error => {
        console.error(error);
      });

    },
    methods: {
      uploadImage(event) {
      this.loading = true; // 显示loading
      this.uploadError = false; // 将uploadError重置为false
      const formData = new FormData();
      const imageFile = event.target.files[0];
      formData.append('image', imageFile);

      // Replace 'http://your-flask-backend-endpoint/upload' with your Flask backend URL
      axios.post(this.$store.state.baseURL + '/api/searchUpdateimge', formData, {
        params: {
          fuzzySearch: this.fuzzySearch // 将 fuzzySearch 值作为查询参数传递
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        // console.log(response.data);
        // 处理上传成功
        // 将response.data传递给父组件
        this.$emit('search-results', response.data);
        // 将drawer的值设置为false
        this.$emit('close-drawer', false);
        this.loading = false; // 隐藏loading
      })
      .catch(error => {
        // 处理上传失败
        console.error('Error uploading image', error);
        console.log('Error message from server:', error.response.data.message);
        this.loading = false; // 隐藏loading
        this.uploadError = true; // 设置uploadError为true，显示错误提示
      });
    },
      toggleTag(tagValue) {
        const index = this.selectedTags.indexOf(tagValue);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        } else {
          this.selectedTags.push(tagValue);
        }
      },
      removeTag(tagValue) {
        this.selectedTags = this.selectedTags.filter(tag => tag !== tagValue);
      },
      updateAdjustableTag(tagValue) {
        // 处理可调整标签值的更改
      },
      submitSearch() {
        const allSelectedTags = [
          ...this.selectedTags,
          ...Object.entries(this.selectedAdjustableTags)
          .filter(([, value]) => value !== 'none')
          .map(([, value]) => value)
        ].join(',');
        // console.log('当前选择的标签:', allSelectedTags);

        const params = {
          allSelectedTags,
          fuzzySearch: this.fuzzySearch  // 将 fuzzySearch 的值作为参数传递
        };

         // 向后端发送搜索请求
        axios.get(this.$store.state.baseURL + '/api/searchBase_str', { params })
        // axios.get(this.$store.state.baseURL + '/api/searchBase_str', { params: { allSelectedTags } })
          // console.log(params)
          .then(response => {
            // 处理搜索结果
            // console.log(response.data);
            // 将allSelectedTags重置为空数组
            // thsis.adjustableTags= [],
            this.selectedTags = [];
            this.adjustableTags.forEach(tag => {
              this.$set(this.selectedAdjustableTags, tag.value, tag.options[0].value);
            });
            const reversedData = response.data.reverse(); 
            // console.log("倒叙："+reversedData) 
            // console.log("正常："+response.data) 
            // 将response.data传递给父组件
            this.$emit('search-results', reversedData);
            // 将drawer的值设置为false
            this.$emit('close-drawer', false);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };
  </script>
  




<style scoped>
  .tag-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;
      /* 添加一些间距在标签和搜索按钮之间 */
      margin-left: 20px; /* 左侧边距 */
      margin-right: 20px; /* 右侧边距 */
  }

  .tag {
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 20px;
      background-color: #f0f0f0;
      color: black;
      /* 未选中标签的文本颜色 */
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
  }

  .tag.selected {
      background-color: #007bff;
      /* color: white; */
  }

  .tag-remove {
      margin-left: 8px;
      cursor: pointer;
  }

  .selectable select {
      border: none;
      background: transparent;
      cursor: pointer;
  }

  .selectable select:focus {
      outline: none;
  }

  .search-button-container {
    margin-left: auto; /* 将左侧空间设为auto，将搜索按钮推到最右侧 */
  }

  .search-button {
      padding: 10px 20px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      /* 增大字体以适应移动端 */
      text-transform: uppercase;
      /* 文字大写 */
      transition: background-color 0.3s ease;
  }


  .search-button:hover {
      background-color: #0056b3;
      /* 鼠标悬停时改变颜色 */
  }


  .upload-demo {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .el-upload__text {
    margin-top: 10px;
  }

  .upload-btn {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1rem;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  text-align: center;
  border: none;
  transition: background-color 0.2s;
}

.upload-btn:hover {
  background-color: #0056b3;
}

  
  /* 响应式设计：针对手机和小屏幕设备的样式 */
  @media (max-width: 768px) {
    .upload-demo {
      width: 100%;
    }
    .tag-container .tag, 
    .tag-container .selectable select, 
    .tag-container .search-button {
    font-size: 12px; /* 缩小按钮的字体大小 */
    padding: 3px 6px; /* 缩小按钮的内边距 */
    }
  }

  .loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  margin-top: 20px;
}

.loading .loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loading p {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

  
</style>


<!-- adjustableTags:[
            {
                value: 'hairstyle',
                options: [{
                        text: '任意发型',
                        value: 'none'
                    },
                    {
                        text: '短发',
                        value: 'short hair'
                    }
                ]
            },
            {
                value: 'hair',
                options: [{
                        text: '任意发色',
                        value: 'none'
                    },
                    {
                        text: '白发',
                        value: 'white hair'
                    }
                ],
            },
            {
                value: 'breasts',
                options: [{
                        text: '任意胸部',
                        value: 'none'
                    },
                    {
                        text: '平胸',
                        value: 'small breasts'
                    }
                ]
            },
            {
                value: 'ears',
                options: [{
                        text: '无兽耳',
                        value: 'none'
                    },
                    {
                        text: '精灵耳',
                        value: 'pointy ears'
                    }
                ]
            },
            {
                value: 'eyescolor',
                options: [{
                        text: '任意瞳色',
                        value: 'none'
                    },
                    {
                        text: '黑瞳',
                        value: 'black eyes'
                    }
                ]
            },
            {
                value: 'wings',
                options: [{
                        text: '无翅膀',
                        value: 'none'
                    },
                    {
                        text: '任意翅膀',
                        value: 'wings'
                    }
                ]
            }
        ] -->

<!--         tags: [
          { text: '连衣裙', value: 'dress' },
          { text: '裙子', value: 'skirt' }
        ] -->