<template>
    <div class="row-high">
        <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">
            <!-- <ImageGrid :images="image1" :showDetailButton="true"/> -->
            <el-row>
                <el-col 
                v-for="item in reversedImageList"
                :key="item.id"
                :span="12" 
                :xs="24"
                :sm="12"
                :md="12" 
                :lg="8">
                
                <el-card style="width: 100%">
                    <img 
                    :src="item.src"
                    style="width: 100%; height: 100%; object-fit: cover;">

                    <div style="padding: 14px;">
                    <span>{{item.name}}</span>
                    
                    <el-button 
                        type="text"
                        @click="open(item.url)">
                        点击查看
                    </el-button>
                    </div>
                </el-card>
                <br>
                </el-col>
            </el-row>


        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        </el-row>
    </div>
</template>


<script>
import ImageGrid from '@/components/ImageGrid.vue';

export default{
    methods: {
    open(url) {
        window.open(url) 
    }
    },
    
    components: {
        ImageGrid,
    },
    computed: {
    reversedImageList() {
      return this.imageList.slice().reverse();
    }
  },

    data() {
        return {
        imageList: [
        {
        id: 0,
        name: '客户案例9-修图服务',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/11.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/IM5pd7CYIoeS4txHwNgcx8vInKd',
        },
        {
        id: 1,
        name: '客户案例8-修图服务',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/10.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/ZSORdDJLNoY5kOx6GDSc5t7HnSf',
        },
        {
        id: 2,
        name: '客户案例7-插画定制',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/7.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/WwbtdsISlosRmaxizltc93tPnAc',
        },
        {
        id: 3,
        name: '客户案例6-插画定制',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/6.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/Lhy7d0FZNoYfK4xseQ0cQCKAnIc',
        },
        {
        id: 4,
        name: '客户案例5-live2d立绘定制',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/5.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/DjDGdVDQqoiQICxU84ZcHNSyn0c',
        },
        {
        id: 5,
        name: '客户案例4-live2d立绘定制',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/1.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/ZRHxdt0G5odLIxxcXNycMXUznRd',
        },
        {
        id: 6,
        name: '客户案例3-live2d定制',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/3.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/JTW4dF0MEopxd9xxqLtccQaWndd',
        },
        {
        id: 7,
        name: '客户案例1-live2d定制',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/4.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/AEWGdvUMioDDZ6xZ2fyc42ksnLe',
        },
        {
        id: 8,
        name: '棕发猫耳客户案例',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E6%A3%95%E5%8F%91%E7%8C%AB%E8%80%B3%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B.jpg',
        url : 'https://uv0i0yy769u.feishu.cn/docx/J3rOdhrMCoXmoqxkONxc8LB1nNe?from=from_copylink',
        },
        {
        id: 9,
        name: '金发猫耳客户案例',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E9%87%91%E5%8F%91%E7%8C%AB%E8%80%B3%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B.jpg',
        url : 'https://uv0i0yy769u.feishu.cn/docx/XUZHditXzow4MPxOpSRcfhNRnsb?from=from_copylink',
        },
        {
        id: 10,
        name: '蝴蝶客户案例',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E8%9D%B4%E8%9D%B6.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/XoEmdnJbBonR5gxP34xcC6ugnhd?from=from_copylink',
        },
        {
        id: 11,
        name: 'Orangeidol客户案例',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/orangeidol.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/Mt0Td9ttBoL1PtxXutWcx9Jdnod?from=from_copylink',
        },
        // 注意id和url的顺序，必须保持一致
        {
        id: 12,
        name: '龙角女孩客户案例',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E9%BE%99%E8%A7%92%E5%A5%B3%E5%AD%A9.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/ZABXdI2r2oDnOfxkxAYcXVudnrb?from=from_copylink',
        },
        {
        id: 13,
        name: '小熊客户案例',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E5%B0%8F%E7%86%8A.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/I5qGdTtTFoorEQxDqfXcYnp4nge?from=from_copylink',
        },
        {
        id: 14,
        name: '猫耳天使',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E7%8C%AB%E8%80%B3%E5%A4%A9%E4%BD%BF.png',
        url : 'https://jxjcqzik9sc.feishu.cn/docx/J1RGdGLZjo2safxI7sWcuTr2nWh',
        },
        {
        id: 15,
        name: '丸子头 jk',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E4%B8%B8%E5%AD%90%E5%A4%B4jk.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/VrXLdUjL3oIut5xrsEucSMCYnbg',
        },
        {
        id: 16,
        name: '超逆天可动性杀人小兔兔',
        src: 'https://s2.x914.com/daemao/i/%E5%AE%A2%E6%88%B7%E6%A1%88%E4%BE%8B/%E8%B6%85%E9%80%86%E5%A4%A9%E5%8F%AF%E5%8A%A8%E6%9D%80%E6%89%8B%E5%85%94%E5%85%94.png',
        url : 'https://jxjcqzik9sc.feishu.cn/docx/Tq15dLhM5oZ4sgxmXw6cqoHpnZb',
        },


        {
        id: 17,
        name: '修图、插画案例',
        src: 'https://s2.x914.com/daemao/i/%E8%83%8C%E6%99%AF%E5%BA%93/9.png',
        url : 'https://uv0i0yy769u.feishu.cn/docx/ZqZpdmFajoNPuCx0rUYc91FJnbH',
        },
       
    ]
        }
    },


}


</script>


<style>
.row-high{
margin-top: 50px; /* 设置上边距为 50px，可以根据需要调整数值 */
}
.el-row {
  display: flex;
  flex-wrap: wrap; 
}
</style>

