<template>
  <div class="video-player">
    <!-- <iframe 
      :src="videoUrl"
      :width="width" 
      :height="height"
      frameborder="0" 
      allowfullscreen="true">
    </iframe> -->

    <video
    :src="videoUrl"
    :width="width" 
    :height="height"
    frameborder="0" 
    allowfullscreen="true">
    </video>


  </div>
</template>

<script>
export default {
  props: {
    videoUrl: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '320px'
    }
  }
}
</script>

<style>
.video-player {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  
}

.video-player iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
}
</style>

