<template>
    <div  class="row-high">
          <el-row :gutter="10">
          <el-col :xs="2" :sm="2" :md="5" :lg="3" :xl="3"><div class="grid-content"></div></el-col>
          <el-col :xs="20" :sm="20" :md="14" :lg="18" :xl="18">
              
              <div>
                  <p class="info">  
                  <p>&nbsp</p>
                  <p>&nbsp</p>
                  <p>关于我们</p>
                  <p>大鹅猫工作室是一家超级有创意的AI 🎨 工作室</p>
                  <p>我们拥有一支由资深工程师、设计师和产品经理组成的梦幻团队 💫</p>
                  <p>我们携手合作，将您的创意变为现实，并呈现给您令人振奋的高品质产品。</p>
                  <br>
                  </p>
                  <button class="back btn" @click="goToLink" style="color:blue;">点我进入淘宝</button>
                  <br>
                  <button class="back btn" @click="goToLink2" style="color:blue;">点我进入爱发电</button>
                  <br>
                  <button class="back btn" @click="goToLink3" style="color:blue;">点我进入货摊网</button>
                  <br>
                  欢迎在淘宝、B站、小红书、咸鱼等搜索大鹅猫工作室咨询
                  <img src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E7%BE%A4%E8%81%8A/astronaut.png" class="center-img">
                  <br>
                  <br>
                  <GroupChat></GroupChat>
              </div>
          </el-col>
          <el-col :xs="2" :sm="2" :md="5" :lg="3" :xl="3"><div class="grid-content"></div></el-col>
          </el-row>
      </div>
  </template>
  
  <script>
  import GroupChat from '../components/GroupChat.vue';
  
  export default {
    components: {
      GroupChat,
    },
    methods: {
      goToLink() {
        window.location.href = 'https://item.taobao.com/item.htm?ft=t&id=702345281626&spm=a21dvs.23580594.0.0.52de3d0d4wL978';
      },
      goToLink2() {
        window.location.href = 'https://afdian.net/a/daemao';
      },
      goToLink3() {
        window.location.href = 'https://daemao.huotan.com/';
      },
    }
  
  
  }
  
  
  </script>
  
  <style>
.center-img {
  display: block;
  margin: 0 auto;

  animation: sway 3s ease-in-out infinite;

}

@keyframes sway {
  0% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  100% { transform: rotate(-5deg); }  
}
  
  </style>
  