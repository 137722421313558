<template>
    <el-row :gutter="10">
      <el-col :xs="3" :sm="3" :md="3" :lg="8" :xl="8"><div ></div></el-col>
      <el-col :xs="18" :sm="18" :md="18" :lg="8" :xl="8"><div>
            <canvas ref="chartCanvas"></canvas>
      </div></el-col>
      <el-col :xs="3" :sm="3" :md="3" :lg="8" :xl="8"><div></div></el-col>
    </el-row>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  mounted() {
    const ctx = this.$refs.chartCanvas.getContext('2d');

    new Chart(ctx, {
      type: 'radar',
      data: {
        labels: [
          '设计质感',
          '角色明确',
          '细节丰富',
          '订制程度',
          '售价便宜',
          '制作周期'
        ],
        datasets: [ {
          label: '设计区',
          data: [100, 69, 90, 50, 56,70],
          fill: true,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)'
        },{
          label: '精图区',
          data: [28, 88, 40, 19, 85,50],
          fill: true,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        }]
      },
      options: {
        scale: {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 100,
            stepSize: 20,
          }
        }
      }
    });
  }
};
</script>

<style>
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
</style>