<template>
    <div class="row-high">
        <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">
            <div class="grid-content" style="display:flex;justify-content:space-between;">

                <ImageGrid :images="currentPageData" :showDetailButton="true"/>
                <el-row justify="center">

                <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                <el-button @click="prevPage">上一页</el-button>
                </el-col>

                <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">  
                    <el-input 
                    v-model.number="currentPage"
                    @change="handlePageChange"
                    type="number" 
                    min=1    
                    placeholder="请输入页码"
                    clearable
                    />
                </el-col>

                <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                <el-button @click="nextPage">下一页</el-button>
                </el-col> 
                </el-row>
            </div>
            <p style="font-size: 15px;text-align: center;">输入框内可输入页码，总页数为：{{ totalPages }}</p>


        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        </el-row>
    </div>
</template>


<script>
import axios from 'axios';
import ImageGrid from '@/components/ImageGrid.vue';

export default{
    computed: {
        currentPageData() { 
        // 按分页取数据
        const start = (this.currentPage - 1) * this.pageSize
        const end = start + this.pageSize
        return this.images.slice(start, end)
      },
    },
  

    methods: {
      handlePageChange(page) {
    if (page > this.totalPages) {
      this.currentPage = this.totalPages
    }
  } ,
      scrollToTop() {
        document.documentElement.scrollIntoView({
          behavior: 'smooth' 
        })
      },
        descChanged() {
        this.currentPage = 1  
      },
      nextPage() {
        if(this.currentPage >= 1) {
          this.currentPage++ 
        }
        this.scrollToTop() 

      } ,
      prevPage() {
        if(this.currentPage > 1) {
          this.currentPage--  
        }
        this.scrollToTop() 

      } ,
    },
    created() {
      axios.get(this.$store.state.baseURL+'/api/dataBackimg')
        .then(response => {
          this.images = response.data
          this.totalPages = Math.floor(this.images.length / this.pageSize + 1)

          
        })
    },

    components: {
        ImageGrid,
    },

    data() {
        return {
        currentPage: 1, //分页
        pageSize: 30,
        totalPages: 0,

        images: [],
        // images: [
        // {
        // id: 1,
        // name: '默认表情',
        // src: 'https://s2.x914.com/daemao/i/背景库/1.png',
        // src_yuan : 'https://s2.x914.com/daemao/i/%E8%83%8C%E6%99%AF%E5%BA%93/1.png',
        // },
        // {
        // id: 2,
        // name: '默认表情',
        // src: 'https://s2.x914.com/daemao/i/%E8%83%8C%E6%99%AF%E5%BA%93/2.png',
        // src_yuan : 'https://s2.x914.com/daemao/i/%E8%83%8C%E6%99%AF%E5%BA%93/2.png',
        // },
    // ]
        
        }
    },


}


</script>


<style>
.row-high{
margin-top: 50px; /* 设置上边距为 50px，可以根据需要调整数值 */
}
</style>