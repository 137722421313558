<template>
    <div class="tv-box">
      <div class="antenna left"></div>
      <div class="antenna right"></div>
      <video id="video-player" ref="videoPlayer" controls>
        <source :src="videoUrl" type="video/mp4">
      </video>
    </div>
  </template>
  
<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default {
props: {
    videoUrl: {
    type: String,
    required: true
    }
},
mounted() {
    this.initializePlyr();
},
updated() {
    this.initializePlyr();
},
methods: {
    initializePlyr() {
    const player = new Plyr(this.$refs.videoPlayer);
    }
}
}
</script>

<style scoped>
.tv-box {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    display: inline-block;
    position: relative;
}

.antenna {
    position: absolute;
    top: -8px;
    width: 10px;
    height: 20px;
    background-color: gray;
    transform-origin: center center;
    border-radius: 4px;
}

.left {
    left: 20%;
    margin-left: -16px;
    transform: rotate(-45deg);

}

.right {
    right: 20%;
    margin-right: -16px;
    transform: rotate(45deg);
}
</style>

