<template>
  <div class="chat">
    <p class="chatP">您有任何问题欢迎联系我们！</p>
    <p class="chatL">获得最新特价活动通知</p>
    <p class="chatL">软件使用指导和直播心得探讨</p>
    <p class="chatL">测试版软件优先更新</p>
    <p class="chatL">请加入大鹅猫工作室用户群:</p>
    <p class="chatL">QQ群：487147819</p>
    <br>
    <p class="chatP">免费皮套分享压缩包解压密码：daemao.top</p>
    <br>
    <p class="chatP">点击下方任意链接，均可以直接购买（淘宝有专属客服，购买体验更好哦~）：</p>
    <p class="chatL"><a href="https://m.tb.cn/h.5P7H7Nx?tk=gl3TWV0sDwM" style="color:blue">淘宝</a></p>
    <p class="chatL"><a href="https://afdian.net/a/daemao" style="color:blue">爱发电</a></p>
    <p class="chatL"><a href="https://daemao.huotan.com/" style="color:blue">货摊网</a></p>
    <div class="groupchat">          
        <img src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E7%BE%A4%E8%81%8A/1.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'groupchat'   
}

</script>

<style>
/* .chat {
  text-align: left; 
} */

.chatP{
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}

.chatL{
  font-size: 15px;
  text-align: left;
}

.groupchat {
  width: 300px;
  display: flex;
  justify-content: center; 
  padding-bottom: 100%; /* 图片高宽比例 */

}

.groupchat img {
  width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 300px;
}
</style>