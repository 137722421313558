<template>
    <router-link :class="classes" :to="to">
        <slot></slot>   
    </router-link>
</template>


<script>
export default {
    name: 'Button',
    props: ['to', 'classes']  
}
</script>