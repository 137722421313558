<!-- painttingquet.vue -->
<template>
    <div  class="row-high">
        <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="3" :xl="3"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="18" :xl="18">
            <el-icon><MoonNight /></el-icon>
            <div v-for="card in Cards" :key="card.nikename" class="card">
                <span class="card-title">{{card.nikename}}</span>
                <p>{{card.note}}</p>

                <div v-if='showImage'>
                  <p>随机图片展示，如下：</p>
                  <img :src="getRandomImage(card).src">
                </div>

                <br>


                <div v-if=" card === currentCard">
                  <p>现在是第{{currentImgId}}张图片（由于图片分辨率较高，若图片没有刷新，请耐心等待下方图片刷新）</p>
                  <img :src="imageSrc">
                </div>

                <p>本模型的更多例图,请点击“上一张”、“下一张”按钮展示</p>
                <el-button  type="primary" text-color="#000" plain @click="prevImage(card);onCardClick(card)">上一张</el-button>  
                <el-button  type="primary" text-color="#000" plain @click="nextImage(card);onCardClick(card)">下一张</el-button>



                <el-button  class="button"  @click="open(card)">本按钮查看大量图片(可能导致页面卡顿)，手机端慎点</el-button>    
                <el-drawer
                  v-if="drawerVisible"
                  title=""
                  :visible.sync="drawer_free_box"
                  :direction="direction"
                  :before-close="handleClose_box"
                  size="70%">
                      点击下方暗处，即可关闭此弹窗（图片加载需要一定的时间，请稍片刻）
                    <Detail :images="currentDetil.images"></Detail>
                </el-drawer>

                <el-divider></el-divider>
            </div>

        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="3" :xl="3"><div class="grid-content"></div></el-col>
        </el-row>
    </div>

</template>



<script>
import Detail from '../components/Detail.vue'
import axios from 'axios'


export default {
  computed: {
  randomCardImage() {
    return card => {
      if (!this.randomImages[card.id]) {
        this.randomImages[card.id] = this.getRandomImage(card)
      }
      return this.randomImages[card.id]
    }
  }
},

  watch: {
  randomImgLoaded(newValue) {
    if (!newValue) {
      this.randomImgSrc = this.getRandomImage().src
      this.randomImgLoaded = true
    }
  }
},

  created() {
      axios.get(this.$store.state.baseURL+'/api/cards')
        .then(response => {
          this.Cards = response.data
        })

    },


    methods: {

      onButtonClick() {
        // 点击时设置为false
        this.showImage = false
      } ,

      onCardClick(card) {

        this.currentCard = card;

      },

      nextImage(card) {
        // 当前图片索引
        let index = this.currentImgIndex;
        // 图片总数
        let length = card.images.length;
        // 索引加1,循环到开头  
        index = (index + 1) % length;
        // 更新当前索引
        this.currentImgIndex = index; 
        // 更新图片src
        this.imageSrc = card.images[this.currentImgIndex].src;
        // 获取当前图片 id
        const id = this.Cards[0].images[index].id
        // 更新 currentImgId
        this.currentImgId = id 
        //判断边界
        if(this.currentImgIndex === this.length - 1) {
          this.nextDisabled = true
        }else {
          this.nextDisabled = false 
        }
        

        // 调用重置方法
        this.resetBoundaryCheck()

      },

    prevImage(card) {
      // 当前图片索引 
      let index = this.currentImgIndex;
      // 图片总数
      let length = card.images.length;
      // 索引减1,循环到末尾
      index = (index - 1 + length) % length;
      // 新当前索引
      this.currentImgIndex = index;
      // 更新图片src
      this.imageSrc = card.images[this.currentImgIndex].src;
      // 获取当前图片 id
        const id = this.Cards[0].images[index].id
      // 更新 currentImgId
      this.currentImgId = id 
      // 判断边界
      if (this.currentImgIndex === 0) {
        this.prevDisabled = true
      } else {
        this.prevDisabled = false
      }


      // 调用重置方法
      this.resetBoundaryCheck()  

    },

    resetBoundaryCheck() {
      // 重置判断
      this.prevDisabled = false 
      this.nextDisabled = false

      // 重新计算
      if(this.currentIdx === 0) {
        this.prevDisabled = true
      } 
      if(this.currentIdx === this.length - 1) {
        this.nextDisabled = true
      }
    },


    open(card) {
        this.currentDetil = card 
        this.drawer_free_box = true
        this.drawerVisible = true
    },

    handleClose_box() {
        this.drawer_free_box = false;
    },
    
    getRandomImage(card) {
      const images = card.images
      const index = Math.floor(Math.random() * 1)
      return images[index]
    }
  },
  components: {
    Detail,
  },
  data() {
    return {
      drawerVisible: false, // 在 data 中定义
      currentCard: null, // 声明 currentCard 属性
      direction: 'ttb',
      drawer_free_box: false,
      currentImgIndex: 0,
      imageSrc : '',
      currentImgId: null,
      drawer: false,
      showImage: true,
      imageVisible: new Map(),

      currentDetil:null,
      randomImgLoaded: false,

      prevDisabled: false,
      nextDisabled: false,//边界判断


      Cards:[],
      Cards1: [
        { 
          nikename:'万物v5',
          note: '很适合动漫的模型，色彩自然，角色精致，现代动漫的风格',
          imgnumbers: 3,
          images: [
              {
                id : '1',
                src :'https://s2.x914.com/daemao/i/画风库/万物V5/1.jpg',
              },{
                id : '2',
                src :'https://s2.x914.com/daemao/i/画风库/万物V5/2.jpg',
              },{
                id : '3',
                src :'https://s2.x914.com/daemao/i/画风库/万物V5/3.jpg',
              },

          ]
        },{ 
          nikename:'写实卡通',
          note: '很适合动漫的模型，色彩自然，角色精致，现代动漫的风格',
          imgnumbers: 5,
          images: [
              {
                id : '1',
                src :'https://s2.x914.com/daemao/i/画风库/写实卡通/1.jpg',
              },{
                id : '2',
                src :'https://s2.x914.com/daemao/i/画风库/写实卡通/2.jpg',
              },{
                id : '3',
                src :'https://s2.x914.com/daemao/i/画风库/写实卡通/3.jpg',
              },{
                id : '4',
                src :'https://s2.x914.com/daemao/i/画风库/写实卡通/4.jpg',
              },{
                id : '5',
                src :'https://s2.x914.com/daemao/i/画风库/写实卡通/5.jpg',
              },

          ]
        },
        
      ]
    }
  }
}
</script>


<style>
.row-high{
margin-top: 50px; /* 设置上边距为 50px，可以根据需要调整数值 */
}

.card-title {
  font-size: 20px; 
  font-weight: bold;
}

p {
    font-size: 16px; /* 根据需要调整字体大小 */
  }
</style>