<!-- ImageGrid.vue -->

<template>
    <div class="image-grid">
      <ImageBlock
        v-for="image in images"
        :key="image.id" 
        :image="image" 
        :show-detail-button="showDetailButton"
        :show-video="showVideo"
        :showWatermark="showWatermark"
        :showthumbsUp="showthumbsUp"
        @selectArtStyle="handselectArtStyle"
      />
    </div>
  
  </template>
  
  <script>
  import ImageBlock from './ImageBlock.vue'

  export default {
    data() {
      return {
        loadedImages: [], // 已加载的图片
        lora2 : null,
      }
    },

    mounted() {
      // 初始化加载第一批图片
      this.loadImages(); 
    },

    methods: {
      handselectArtStyle(lora){
        this.lora2 = lora;
        console.log('2:'+ this.lora2);
        this.$emit('lora-updated', lora); // 发送lora2的值给父组件
      },
      loadImages() {
        // 最多加载24张
        const loadCount = Math.min(24, this.images.length - this.loadedImages.length);
        
        for (let i = 0; i < loadCount; i++) {
          this.loadedImages.push(this.images[i]); 
        }
      } 
    },

      
    components: {
      ImageBlock,
    },
    
    props: {
      images: {
        type: Array,
        required: true
      },
      showDetailButton: {
        type: Boolean,
        default: false
      },
    showVideo:{
      type: Boolean,
      default: false
    },
    showWatermark: {
      type: Boolean,
      default: false
    },
    showthumbsUp:{
      type: Boolean,
      default: false
    },

    }
  
  }
  </script>
  
<style>
.image-grid {
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: 0 auto;


  display: grid; 
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px; 
}

@media (max-width: 650px) {
  .image-grid {
    width: 100%;
    height: 100%;  

  }
}

.image-frame {
  width: 100%;
  height: 100%;  

  border-radius: 10px;
  border: 1px solid #ddd;
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px);
  
  display: flex;
  align-items: center;
  justify-content: center;
  /* fsa */
}

  </style>