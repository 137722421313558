<template>
     <div class="el-row-high">
      <div class="text-container">
        大鹅猫工作室——专业Live2d设计工作室
        <br>
        <br>
      </div>
      <!-- 滚动条 -->
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="grid-content text-container ">
             <br>
              <p>大鹅猫工作室致力于优质Live2d设计
              <br>
              实现高质量的人像、动态图、表情等内容的制作
              </p>
          </div>

          
            <div id="banner">
              <div class="img-list img-wrapper" ref="imgListOne">
                <div
                  class="img-box"
                  v-for="(img, index) in images"
                  :key="index"
                  :id="img.id ? 'last-img-box' : ''"
                >
                  <div class="info">
                    <h3>{{ img.text }}</h3>
                  </div>
                  <img :src="img.src" :alt="img.alt" />
                </div>
              </div>
            </div>
          
            <div class="button-container">
              <button @click="clickHandler('last')"><i class="bi bi-arrow-left-circle"></i></button>
              <button @click="clickHandler('next')"><i class="bi bi-arrow-right-circle"></i></button>
            </div>

          <div class="text-container">
            只需我们的一点魔法，变出南瓜马车玻璃鞋，午夜也不会消失
            <br>
            不再担心华丽舞裙高昂的价签
          </div>
        </el-col>
      </el-row>

      <!-- 视频播放组件 -->
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="grid-content text-container">
            <!-- <video-player 
              video-url="https://player.bilibili.com/player.html?bvid=BV1sP411k7tm"
            ></video-player> -->

        <video playerType='video' width="70%" height="50%" controls> 
          <source src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E9%A6%96%E9%A1%B5/%E8%A7%86%E9%A2%91/daemao1.mp4" type="video/mp4">
        </video>

          </div>
        </el-col>
      </el-row>



      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div>
            <div class="text-container">
              <p>
              极速出图，低成本快速试播
              <br>
              壁纸、插图、表情包、开场视频一应俱全
              <br>
              手工Iive2d全身可动，百种画风，专业图层拆分和贴图绘制
              </p>
            </div>
            <!-- <nine-window></nine-window> -->
            <!-- <ImageGrid :images="images2" playerType="iframe"/> -->
            <!-- <p class="gray-text small-text">
              *注：AI动捕模型可自助捏表情，嘴型、眉型也可改变，做表情的同时仍支持面捕，不同模型使用通用表情效果可能不同*
            </p> -->
          </div>
        </el-col>
      </el-row>


      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div>
            <el-divider><i class="bi bi-cloud-moon"></i></el-divider>
          </div>

          <div class="flex-row">
          <!-- <el-col>
            <a style="font-size: large;  display: flex;  justify-content: center;  align-items: center;  flex-direction: column;">AI动捕模型展示</a>
            <video playerType='video' width="100%" height="50%" controls> 
            <source src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E9%A6%96%E9%A1%B5/%E8%A7%86%E9%A2%91/2.mp4" type="video/mp4">
            </video>
          </el-col> -->

          <el-col>
            <!-- 第二个视频 -->
            <a style="font-size: large;  display: flex;  justify-content: center;  align-items: center;  flex-direction: column;">手工Live2d效果展示</a>
            <video playerType='video' width="100%" height="50%" controls> 
            <source src="https://s2.x914.com/daemao/i/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E9%A6%96%E9%A1%B5/%E8%A7%86%E9%A2%91/3.mp4" type="video/mp4">
            </video>
          </el-col>
                  
          </div>

        </el-col>
          <div class="grid-content text-container">
            <br>
            可动范围为控制眉毛、嘴巴、眼睛、头部、肩部、侧身、呼吸等动作
            <br>
            可快捷键切换表情，目前AI动捕仅支持半身（从头部到大腿根）
            <br>
            Live2d可半身或全身，支持头部xyz、身体xyz,眉毛、3x3口型、全身物理，呼吸、按键表情、尾巴耳朵动效等
          </div>
        <el-col>

        </el-col>
      </el-row>


      
      <!-- <el-row :gutter="10"> -->
        <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"> -->
          <div>
            <el-divider><i class="bi bi-brightness-alt-high"></i></el-divider>
          </div>
          <div class="grid-content  text-container">
            <div class="text-container">
              部分角色图效果展示
            </div>
          </div>
          <ImageGrid :images="images3"/>
        <!-- </el-col> -->
      <!-- </el-row> -->

      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="grid-content text-container">
            q版表情包、壁纸、直播开场等其他参考内容可浏览客户案例页面查看
            <br>
            手工Iive2d提供moc模型文件，可动范围大、灵活度高
            <br>
            支持全身可动和物理效果
          </div>
        </el-col>
      </el-row>

      
      

      <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"><div><el-divider></el-divider></div></el-col>
        <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
          <!-- <div> -->
            <!-- <el-divider><i class="bi bi-clouds"></i></el-divider> -->
          <!-- </div> -->
          <!-- <div class="grid-content text-container"> -->
            <br>
            <br>
            <br>
            <GroupChat></GroupChat>
          <!-- </div> -->
          <!-- <priceClass></priceClass> -->
        </el-col>
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"><div><el-divider></el-divider></div></el-col>
      </el-row>

     </div>
  </template>
  
<script>
import Button from '@/components/Button.vue';
import nineWindow from '../components/nineWindow.vue';
import ImageGrid from '@/components/ImageGrid.vue';
import miniprice from '../views/miniprice.vue';
import priceClass from '@/components/priceClass.vue';
import axios from 'axios';
import GroupChat from '../components/GroupChat.vue';


  export default {
    created() {
      axios.get(this.$store.state.baseURL+'/api/dataChatu')
        .then(response => {
          this.images3 = response.data
        })
    },
    components: {
      GroupChat,
      priceClass,
      miniprice,
      ImageGrid,
        Button,
        // videoPlayer,
        nineWindow
    },
    data() {
      return {
        images: [
          { src: 'https://s2.x914.com/daemao/i/官网素材/首页/轮播图/1.png', alt: '', text: '只需要传统live2d半身的价格'  },
          { src: 'https://s2.x914.com/daemao/i/官网素材/首页/轮播图/2.png', alt: '', text: '服务过多家企业及个人客户'  },
          { src: 'https://s2.x914.com/daemao/i/官网素材/首页/轮播图/3.png', alt: '', text: '提供live2d、插图、表情包'  },
          { src: 'https://s2.x914.com/daemao/i/官网素材/首页/轮播图/4.png', alt: '', text: '开场动画等全套服务'},          
          { src: 'https://s2.x914.com/daemao/i/官网素材/首页/轮播图/5.png', alt: '',text: '咸鱼淘宝100％好评，拥有良好口碑'},
          { id: 'last', src: 'https://s2.x914.com/daemao/i/官网素材/首页/轮播图/9.png', alt: '' ,text: '欢迎在淘宝、咸鱼搜索大鹅猫工作室咨询'},
        ],

    images3: [
      // {
      //   id: 1,
      //   name: '',
      //   src: 'https://s1.imagehub.cc/images/2023/07/15/4811d1c38b0dd58e8.jpeg'
      // },
      // {
      //   id: 2, 
      //   name: '',
      //   src: 'https://s1.imagehub.cc/images/2023/07/15/30a947d5cd7008b35.jpeg',
      // },
   
    ],

        index: 0,
        animationTime: 0.5,
        currentIndex: 0,
        interval: null,
      };
    },
    computed: {
      postSize() {
        return parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--post-size').replace("vw", ""));
      },
      postSpacing() {
        return parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--post-spacing').replace("vw", ""));
      },
      imgBoxLength() {
        return this.postSize + 'vm' + this.postSpacing + 'vm'; 
      },
    },


      beforeDestroy() {
      this.stopAutoplay(); 
    },

    methods: {
      stopAutoplay() {
        clearInterval(this.interval);
      },
      startAutoplay() {
          this.interval = setInterval(() => {
            this.currentIndex++;
            this.currentIndex = this.currentIndex % this.images.length;
            this.updateImages(); 
          }, 30000); // 切换间隔时间
        },
        clickHandler(flag) {
        if (flag === 'next') {
            this.nextImage();
        } else {
            this.prevImage();
        }
        this.updateImageText();
        },
        updateImageText() {
        const text = this.images[this.index].text;
        document.querySelector('.info h3').innerText = text;
        },

      nextImage() {
        this.$refs.imgListOne.style.left = -(this.index * this.imgBoxLength) + 'vm';
        this.images.unshift(this.images.pop());
        this.index--;   
      },

      prevImage() {    
        this.$refs.imgListOne.style.left = -(this.index * this.imgBoxLength) + 'vm';    
        this.images.push(this.images.shift());
        this.index++;   
      }

    },
  };
</script>
  
<style>
.gray-text {
  color: #999999;
}

.small-text {
  font-size: smaller;
}
.gray-text {
  color: #999999;
}

.small-text {
  font-size: smaller;
}

/* 当屏幕宽度大于某个阈值时水平排列 */ 
@media (min-width: 768px) {
  .flex-row {
    display: flex;
  }
}

/* 当屏幕宽度小于某个阈值时竖直排列 */
@media (max-width: 767px) {
  .flex-row {
    display: block;
  }
}


/* 响应式布局 */
@media screen and (max-width: 767px) {
  .small-text {
    font-size: smaller;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .small-text {
    font-size: smaller;
  }
}

@media screen and (min-width: 1024px) {
  .small-text {
    font-size: smaller;
  }
}


.info h3 {
  font-size: 2vw;
  min-font-size: 16px; /* 最小字体大小 */
}
@media (max-width: 600px) {
  .info h3 {
    font-size: 3vw;
  }
}

@media (max-width: 400px) {
  .info h3 {
    font-size: 4vw;
  } 
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-container button {
  flex-grow: 1;
}

.button-container button i {
  font-size: 24px;
}

.text-container {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2vw; /* 4% of viewport width */
}

.el-row-high{
  margin-top: 60px; /* 设置上边距为 50px，可以根据需要调整数值 */
}

  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    overflow: auto;
    height: auto;
    display: flex;  
    flex-wrap: wrap;
  }

  @font-face {
  font-family: 'Microsoft YaHei';
  src: url('https://cdn.jsdelivr.net/npm/microsoft-YaHei@0.7.70/fonts/zh-cn/microsoft-yahei-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal; 
}

:root {
  --post-spacing: 1.78vw;
  --post-size: 25vw;
  --mask-size: 100vw;
}

* {
  padding: 0;
  margin: 0;
  font-family: Millik, Arial, sans-serif;
  /* font-size: 62.5%; */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  background: #fff;
  background-image: url(../assets/grid.svg);
  background-repeat: repeat;
  background-size: 300px 300px;
}


@media screen and (max-width: 768px) {
  .title-text {
    font-size: 3rem;
    white-space: normal;
    text-align: center;
  }
}



#banner {
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: calc(var(--post-size) / 0.72);
  -webkit-mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNDQwIiBoZWlnaHQ9IjUwMCIgdmlld0JveD0iMCAwIDE0NDAgNTAwIiBpZD0iaiI+CiAgPHBhdGggZmlsbD0icmdiKDIwMCwyMDAsMjAwKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwczI3NS4wNCAxMDAgNzIwIDEwMFMxNDQwIDAgMTQ0MCAwdjUwMHMtMjc1LjA0LTEwMC03MjAtMTAwUzAgNTAwIDAgNTAwVjB6Ii8+Cjwvc3ZnPgo=);
          mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNDQwIiBoZWlnaHQ9IjUwMCIgdmlld0JveD0iMCAwIDE0NDAgNTAwIiBpZD0iaiI+CiAgPHBhdGggZmlsbD0icmdiKDIwMCwyMDAsMjAwKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwczI3NS4wNCAxMDAgNzIwIDEwMFMxNDQwIDAgMTQ0MCAwdjUwMHMtMjc1LjA0LTEwMC03MjAtMTAwUzAgNTAwIDAgNTAwVjB6Ii8+Cjwvc3ZnPgo=);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: var(--mask-size);
          mask-size: var(--mask-size);
  /* position: absolute; */
  top: 10%;
}

#banner .img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  float: left;
  height: calc(var(--post-size) / 0.72);
  -webkit-transform: translate(13.39vw, 0);
          transform: translate(13.39vw, 0);
  -webkit-animation: admission 1.5s;
          animation: admission 1.5s;
}

#banner .img-wrapper .img-box {
  height: 100%;
  display: inline-block;
  margin-right: var(--post-spacing);
  position: relative;
  cursor: pointer;
}

#banner .img-wrapper .img-box .info {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(97, 95, 95, 0.5);
  text-align: center;
  color: #fff9f1;
  font-size: 4rem;
}

#banner .img-wrapper .img-box img {
  width: var(--post-size);
  height: 100%;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
     object-fit: cover;
}

#banner .img-wrapper .img-box:last-child {
  -webkit-transform: translate(-130vw, 0);
          transform: translate(-130vw, 0);
}

.btn-group {
  height: 15vh;
  position: absolute;
  left: 50%;
  bottom: 0%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 1s;
  transition: 1s;
  opacity: 0;
}

.btn-group .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #171717;
  background-color: #000;
  margin: 10px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.btn-group .btn:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background-color: #fff;
}

.btn-group .btn:hover .icon {
  fill: #fff;
}

.btn-group .btn .icon {
  width: 30px;
  height: 30px;
}

.btn-group .btn .right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.img-list {
  left: -85%;
}

.img-wrapper {
  transition: transform 10s ease;
}

@-webkit-keyframes admission {
  0% {
    -webkit-transform: translate(140vw, 0);
            transform: translate(140vw, 0);
  }
  100% {
    -webkit-transform: translate(13.39vw, 0);
            transform: translate(13.39vw, 0);
  }
}

@keyframes admission {
  0% {
    transform: translateX(140vw); 
  }

  100% {
    transform: translateX(13.39vw);
  }
}

#banner .img-wrapper {
  animation: admission 20s linear infinite; 
}
  </style>