<template>
    <div   class="row-high">

        <el-row :gutter="10">
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">

            <div
                title=""
                size="50%">
                    <div style="font-size: 16px;">
                      <br>
                      免费模型领取方法：
                      <br>
                      1.在<a href="https://daemao.top/finProduquet" style="color:blue">成品库</a>中点击免费标签选择免费模型(自带图片可以付费制作)，点击下面的蓝色链接并跳转淘宝（或在咸鱼搜索大鹅猫工作室拍单）：
                      <br>
                      <br>
                      <!-- 【淘宝】https://m.tb.cn/h.5YnEaTL?tk=g2c2dG37UWk CZ3457 「百元live2d虚拟主播形象定制二次元皮套vtuber成品设计vup约稿」 -->
                      <a href="https://m.tb.cn/h.5YnEaTL?tk=g2c2dG37UWk CZ3457" style="color:blue">-跳转请戳我，跳转至淘宝商品页，记得先看下面的操作说明哦-</a>
                      <br>
                      <br>
                      2.联系大鹅猫工作室客服，告诉客服选择的是公用模型，并告知客服模型编号。
                      <br>
                      <br>
                      3.拍下客服发给您的商品，客服将软件和模型发给您，按软件内的教程安装即可使用。
                      <br>
                      <br>
                      4.退款说明：<span style="color:red;">2-3天后确认收货并好评、追评，在<a href="https://daemao.top/freeRefund" style="color:blue">客户退款页面</a>进行登记，收货1-3小时后钱款将全额退回您的支付宝</span>
                      <br>
                      <!-- <br>
                      5.注意事项：我们的产品是ai动捕，请确保您的显卡性能＞GTX1650，<a href="https://daemao.top/detection" style="color:blue">点我检查显卡</a>
                      <br> -->
                      <br>
                    </div>
            </div>

            <formFree></formFree>  
            <el-divider></el-divider>

            <div>
                <GroupChat></GroupChat>
            </div>

        </el-col>
        <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content"></div></el-col>
        </el-row>
    </div>

</template>

<script>
import formFree from '../components/formFree.vue';
import GroupChat from '../components/GroupChat.vue';
export default {
    name: 'freeRefund',
    components: {
        formFree,
        GroupChat,
    }
}




</script>

<style>


</style>
