<template>
  <div class="watermark-wrap">
    <div class="watermark" 
       v-for="offset in offsets" 
      :style="{
        transform: `
          rotate(${rotateDeg}deg) 
          translate(${offset.x}px, ${offset.y}px)
        `  
      }">
      {{ watermarkText }}
    </div>
  </div>
  </template>
  
  <script>
  export default {
    methods: {
      generateOffsets() {
        const xOffsets = [];
        const yOffsets = [];

        // 生成x方向偏移量
        for (let x = -800; x < 2000; x+=100) {
          xOffsets.push(x);
        }

        // 生成y方向偏移量 
        for (let y = -300; y < 2000; y+=150) {
          yOffsets.push(y);
        }

        // 组合偏移量
        const offsets = [];
        xOffsets.forEach((x, index) => {
          if (index % 2 === 0) { 
            // 只取偶数索引的偏移量
            yOffsets.forEach(y => {
              offsets.push({x, y})
            })
          }
        })

        return offsets;
      }
    },
    
    data() {
      return {
        watermarkText: '@大鹅猫工作室',
        rotateDeg: -20, 
        offsets: this.generateOffsets(),

      }
    },
    
  } 
  </script>
  
  <style>
  .watermark-wrap {
    position: relative;
  }
  
  .watermark {
    position: absolute;  
    white-space: pre-wrap;
  
    font-size: 14px;
    color: rgba(0,0,0,0.5);  
  
    pointer-events: none;
  }
  </style>