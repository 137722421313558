<template>
    <el-row :gutter="10">
    <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple"></div></el-col>
    <el-col :xs="20" :sm="20" :md="14" :lg="14" :xl="14">
      <el-table
        class="row-high"
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName">
      
        <el-table-column
          prop="address"
          label="有关售后的部分：Q & A">
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :xs="2" :sm="2" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
</template>
  

  
  <script>
    export default {
      name: 'afterSales',
      methods: {
        tableRowClassName({row, rowIndex}) {
          if (rowIndex % 2 !== 0) {
            return 'warning-row';
          } 
          return '';
        }
      },
      data() {
        return {
          tableData: [{
            address: 'Q:我的程序有损坏，运行不了怎么办？',
          }, {

            address: 'A:一般情况下报错都是因为软件没有放在纯英文目录，请检查软件路径是否为纯英文。若还是运行失败，请运行“调试模式-没反应请运行我.bat”，并按照教程中的流程运行软件，将黑框中的报错信息截图发给客服。'
          }, {

            address: 'Q：我的角色图片搞丢了，之前的草图能不能再发我一份？',
          }, {

            address: 'A:由于隐私问题和存储空间有限的问题，我们只会暂存您的图片、模型等数据七天，如果您需要长期保存，需要您提前告知授权，但是数据没有绝对安全的地方，还请妥善保存您的模型和图片。'
          },{

            address: 'Q：我还可以再找你们给角色换衣服、换表情、换发型、出插画吗？',
          },{
            
            address: 'A:欢迎您随时再来，您如果将先前发给您的角色原图文件发给我们，可以更快的出图。',
          },
        
        
        
        
        
        ]
        }
      }
    }
  </script>


<style>
.row-high{
margin-top: 40px; /* 设置上边距为 50px，可以根据需要调整数值 */
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>