<template>
<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

    <el-form-item label="客户名" prop="name">
      <el-input v-model="ruleForm.name"></el-input>
    </el-form-item>

    <el-form-item label="购买平台" prop="platform">
      <el-select v-model="ruleForm.platform" placeholder="请选择您获取免费产品的平台" style="height:100vm;">
        <el-option label="咸鱼" value="咸鱼"></el-option>
        <el-option label="淘宝" value="淘宝"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="订单编号" prop="serial_num">
      <el-input v-model="ruleForm.serial_num"></el-input>  
    </el-form-item>

    <el-form-item label="退款金额" prop="price">
      <el-input v-model="ruleForm.price" placeholder="直接填入数字即可"></el-input>
    </el-form-item>


    <el-form-item label="支付宝&微信收款码" prop="" label-width="150px">
      <input type="file"  @change="handleImageUpload">
    </el-form-item>

    <el-form-item>
      <el-button @click="submitForm('ruleForm')">提交</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>
  </el-form>

</template>


  <script>
  import axios from 'axios';

    export default {
        components: {
        },
      data() {
        return {
          selectedFile: null ,
          ruleForm: {
            name: '',
            platform: '',
            id:'',
            price: '',
            serial_num:'',
            imageFile: null,
          },
          rules: {

            platform: [
              { required: true, message: '请选择平台', trigger: 'change' }
            ],
            name: [
              { required: true, message: '请输入客户名', trigger: 'blur' },
              { min: 0, max: 50, message: '长度在 0 到 15 个字符', trigger: 'blur' }
            ],
            price: [
            { required: true, pattern: /^\d+(\.\d{1,2})?$/, message: '请输入正确的金额,最多两位小数', trigger: 'blur' },
            ],

            serial_num: [
              { required: true, message: '请输入订单编号' },
              { pattern: /^\d{1,21}$/, message: '订单编号必须为1-21位数字' } 
            ],
            imageFile: [
              { required: true, message: '请上传支付宝收款码', trigger: 'change' }
            ],
          }
        };
      },
      methods: {

        handleImageUpload(e) {
          const file = e.target.files[0];
          this.imageFile = file; 
        },

        submitForm() {
        const randomId = Math.floor(Math.random()*9999999999999999); 
        this.ruleForm.id = randomId.toString();

        this.$refs.ruleForm.validate(valid => {
          if (!this.imageFile) {
              this.$message.error({
            message: '请上传支付宝收款码',
            type: 'error',
            offset: 100
          }
                    
            );
            return false;
          }

          if (valid) {
            this.submitToBackend();

            // console.log(this.ruleForm.platform); // 打印出看是否有文件对象
            // console.log(this.imageFile); // 打印出看是否有文件对象
            // console.log('111')


          }else {
              console.log('error submit!!');
              return false;
            }
        });
      },
      async submitToBackend() {
        try {
          // 构造 FormData
          const formData = new FormData(); 

          // 追加图片文件
          formData.append('image', this.imageFile);

          // 追加表单数据
          formData.append('form', JSON.stringify(this.ruleForm)); 

          console.log(formData.get('image'));


          const res = await axios.post(this.$store.state.baseURL+'/api/submit',  formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (res.data.status === 'success') {
              this.$alert('您的订单已提交,我们将尽快为您处理。建议您截屏本画面的订单号方便后续处理', '提交成功', {
                confirmButtonText: '确定',
                callback: action => {

                }
              });
          }else if (res.data.status === 'error') {
            // 失败提示
            this.$alert('请联系客服', '提交失败');

          }

        } catch (err) {
          this.$alert('请联系客服', '提交失败');

        }
      },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        }
      }
    }
  </script>
  