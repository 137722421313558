<template>
    <div class="demo-image__placeholder">    
    <div class="block">
      <span class="demonstration">默认</span>
      <el-image :src="src"></el-image>
    </div>
    <div class="block">
      <span class="demonstration">自定义</span>
      <el-image :src="src">
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
        <iframe src="https://vip.123pan.cn/1813817191/%E7%9B%B4%E8%BF%9E%E6%B5%8B%E8%AF%95/%E5%95%86%E5%9F%8E%E6%95%B0%E6%8D%AE%E5%BA%93%E7%BB%93%E6%9E%84%20%281%29.xlsx" width="100%" height="600"></iframe>

        <div>
          <img src="https://vip.123pan.cn/1813817191/%E7%9B%B4%E8%BF%9E%E6%B5%8B%E8%AF%95/R.gif" alt="gif" />
        </div>
      </el-image>
      <img src="https://vip.123pan.cn/1813817191/%E7%9B%B4%E8%BF%9E%E6%B5%8B%E8%AF%95/R.gif" alt="GIF Image" width="200" height="200" class="gif-image">

    </div>
        <div class="center">
            <!-- <iframe src="https://workdrive.zohopublic.com.cn/embed/vrz5k2908e7b307d94be2acd58c3246d9c13f?toolbar=false&appearance=light&themecolor=green" scrolling="no" frameborder="0" allowfullscreen=true title="嵌入的代码"></iframe>
            <iframe src="https://workdrive.zohopublic.com.cn/embed/vrz5k6ebe84324f7c4fc78a68aa11d8b247e6?toolbar=false&appearance=light&themecolor=green" scrolling="no" frameborder="0" allowfullscreen=true width="800" height="450" title="嵌入的代码" ></iframe>
            <iframe src="https://workdrive.zohopublic.com.cn/embed/vrz5k2908e7b307d94be2acd58c3246d9c13f?toolbar=false&appearance=light&themecolor=green" scrolling="no" frameborder="0" allowfullscreen=true title="嵌入的代码"></iframe> -->
            <video width="320" height="240" controls>
              <source src="https://vip.123pan.cn/1813817191/%E7%9B%B4%E8%BF%9E%E6%B5%8B%E8%AF%95/%E5%BF%83%E5%A3%B0%E9%AD%94%E6%B3%95%E5%85%A8.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>        
  </div>
</template>
  
  <script>
    export default {
      data() {
        return {
          src: 'https://vip.123pan.cn/1813817191/%E7%9B%B4%E8%BF%9E%E6%B5%8B%E8%AF%95/1111.png'
        }
      }
    }
  </script>



<style>
.center {
  display: flex;
  justify-content: center;
}

iframe {
  max-width: 80%;
  width: 100%;
  height: 60vh;
}

@media screen and (max-width: 768px) {
  iframe {
    max-width: 100%;
    height: 50vh; /* 可根据需要调整在小屏幕设备上的高度 */
  }
}
</style>